import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion, AccordionDetails, AccordionSummary, Box,
    Button,
    Card, CardContent,
    CssBaseline,
    Grid,
    Typography
} from '@mui/material';
import React, {useState} from 'react';

function Health() {
    const panelContents = [
        {
            title: 'Appointments',
            cards: [
                {type: 'Psychiatrist', day: 'Monday', month: 'June', date: '6th', time: '10:00 AM', name: 'Dr. Smith', address: '123 Main St', city: 'San Mateo', mapUrl: 'https://maps.google.com/?q=123 Main St'},
                {type: 'Therapist', day: 'Wednesday', month: 'June', date: '21st', time: '2:00 PM', name: 'Therapist Jones', address: '456 Oak St', city: 'San Francisco', mapUrl: 'https://maps.google.com/?q=456 Oak St'}
            ]
        },
        {
            title: 'Medications',
            cards: [
                {name: 'Methylphenidate', dosage: '20mg 1 time a day', refill: '6/21', doctor: 'Dr. Smith', pharmacy: 'CVS Pharmacy', phone: '(123) 456-7890', url: 'https://www.drugs.com/methylphenidate.html'},
                {name: 'Clonidine', dosage: '0.2mg 1 time a day', refill: '8/22', doctor: 'Dr. Johnson', pharmacy: 'Walgreens', phone: '(098) 765-4321', url: 'https://www.drugs.com/clonidine.html'},
                {name: 'Sertraline', dosage: '50MG 1 time a day', refill: '10/15', doctor: 'Dr. Lee', pharmacy: 'Rite Aid', phone: '(555) 123-4567', url: 'https://www.drugs.com/sertraline.html'}
            ]
        },
        {
            title: 'Notes',
            cards: [
                {date: '5/20/2024', observation: 'Child was very anxious during the morning routine.'},
                {date: '5/21/2024', observation: 'Child showed improved behavior after school.'}
            ]
        }
    ];

    const [expandedPanels, setExpandedPanels] = useState(new Map(panelContents.map((panel) => [panel.title, false])));

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedPanels(new Map(expandedPanels.set(panel, isExpanded)));
    };

    const renderAppointmentCard = (card) => (
        <Card key={card.type} sx={{marginBottom: '16px', display: 'flex', bgcolor: '#FFF3E0'}}>
            <Box sx={{width: '60%', bgcolor: '#FFD180', display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
                <CardContent>
                    <Typography variant="h6" sx={{marginBottom: '2px'}}>
                        {card.name}
                    </Typography>
                    <Typography variant="body2" sx={{marginBottom: '2px'}}>
                        {card.day},
                        {card.month} {card.date}
                    </Typography>
                    <Typography variant="body2" sx={{marginBottom: '2px'}}>
                        {card.time}
                    </Typography>
                    <Typography variant="body2" component="a" href={card.mapUrl} target="_blank" rel="noopener noreferrer">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1}}>
                                <span style={{display: 'block'}}>{card.address}</span>
                                <span style={{display: 'block'}}>{card.city}</span>
                            </div>
                            <img src="map.png" alt="Map Icon" style={{width: '24px', height: '24px', marginLeft: '8px'}} />
                        </div>
                    </Typography>
                </CardContent>
            </Box>
            <Box sx={{width: '40%', padding: '8px'}}>
                <img src="/calendar.png" alt="Appointment" style={{width: '100%', height: 'auto'}} />
            </Box>
        </Card>
    );

    const renderMedicationCard = (card) => (
        <Card key={card.name} sx={{marginBottom: '16px', display: 'flex', bgcolor: '#E0F7FA'}}>
            <Box sx={{width: '30%', bgcolor: '#80DEEA', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src="/pills.jpg" alt="Medication" style={{width: '100%', height: '100%'}} />
            </Box>
            <Box sx={{width: '70%', padding: '8px'}}>
                <CardContent>
                    <Typography variant="h6">
                        {card.url ? (
                            <Button
                                component="a"
                                href={card.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                color="primary"
                                sx={{textTransform: 'none', marginBottom: '5px'}}
                            >
                                {card.name}
                            </Button>
                        ) : (
                            card.name
                        )}
                    </Typography>
                    <Box sx={{marginLeft: '5px'}}>
                        <Typography variant="body2" sx={{marginBottom: '2px'}}>
                            {card.dosage}
                        </Typography>
                        <Typography variant="body2" sx={{marginBottom: '2px'}}>
                            {card.doctor}
                        </Typography>
                        <Typography variant="body2" sx={{marginBottom: '2px'}}>
                            {card.pharmacy}
                        </Typography>
                        <Typography variant="body2" sx={{marginBottom: '2px'}}>
                            {card.phone}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2">
                                Next Refill: {card.refill}
                            </Typography>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{marginLeft: 2, textTransform: 'none'}}
                            >
                                Request Refill
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Box>
        </Card>
    );

    const renderNoteCard = (card) => (
        <Card key={card.date} sx={{marginBottom: '16px', display: 'flex', bgcolor: '#F3E5F5'}}>
            <Box sx={{width: '25%', bgcolor: '#CE93D8', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src="/note.png" alt="Note" style={{width: '100%', height: 'auto'}} />
            </Box>
            <Box sx={{width: '75%', padding: '8px'}}>
                <CardContent>
                    <Typography variant="body2" sx={{marginBottom: '2px'}}>
                        Date: {card.date}
                    </Typography>
                    <Typography variant="body2">
                        Observation: {card.observation}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );

    const getImageForPanel = (title) => {
        switch (title) {
            case 'Appointments':
                return 'appointments.png';
            case 'Medications':
                return 'medications.png';
            case 'Notes':
                return 'notes.png';
            default:
                return 'default-image.jpg';
        }
    };

    return (
        <div>
            <CssBaseline />
            <Box sx={{bgcolor: '#A8C0D0', padding: 2, borderRadius: '15px'}}>
                <Grid container spacing={2}>
                    {panelContents.map((panel, index) => (
                        <Grid item xs={12} sm={12} md={12} key={index}>
                            <Accordion
                                expanded={expandedPanels.get(panel.title)}
                                onChange={handleChange(panel.title)}
                                sx={{backgroundColor: '#A8C0D0', boxShadow: 'none'}}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                    sx={{backgroundColor: '#e0e0e0', borderRadius: '25px'}}
                                >
                                    <img src={getImageForPanel(panel.title)} alt={panel.title} style={{width: '30px', height: '30px', marginRight: '8px'}} />
                                    <Typography sx={{flexGrow: 1,  display: 'flex', alignItems: 'center'}}>
                                        {panel.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {panel.title === 'Appointments' && panel.cards.map(renderAppointmentCard)}
                                    {panel.title === 'Medications' && panel.cards.map(renderMedicationCard)}
                                    {panel.title === 'Notes' && panel.cards.map(renderNoteCard)}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
}

export default Health;