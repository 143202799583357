import {AppBar, Box, Button, CssBaseline, Paper, Tab, Tabs, Typography} from '@mui/material';
import React, {useState, useContext} from 'react';
import {AuthContext} from './AuthContext';
import Health from './Health';
import Resources from './Resources';
import NavBar from './Navigation';
import '../scrollbar.css';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function Profile() {
    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const {resetIntroduction, logout} = useContext(AuthContext);

    const handleLogout = () => {
        logout(); // Call the logout function from AuthContext
        resetIntroduction();  // Reset introduction after signing out
    };

    return (
        <div>
            <CssBaseline />
            <NavBar />
            <Box sx={{
                width: '100%',
                maxWidth: '35rem',
                margin: 'auto',
                my: '10px',
                '@media (max-width: 600px)': {width: '100%', height: '80vh'},
                '@media (min-width: 600px)': {width: '90%', height: '80vh'},
            }}>
                <Paper elevation={3} className="profile"
                    sx={{
                        bgcolor: '#D0E0F0',
                        padding: {xs: 1, sm: 2},
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        borderRadius: '5px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>
                    <AppBar position="static" sx={{borderRadius: '10px 10px 0 0', bgcolor: '#D0E0F0'}}>
                        <Tabs
                            value={tabIndex}
                            onChange={handleChange}
                            aria-label="profile tabs"
                            centered
                            variant="fullWidth"
                            indicatorColor="primary" // Set the indicator color
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: '#A8C0D0' // Custom color for the indicator
                                }
                            }}
                            sx={{
                                '& .MuiTabs-flexContainer': {

                                },
                                '& .MuiTab-root': {
                                    width: '50%',
                                    borderRight: '0px solid #000000',
                                    '&:last-child': {
                                        borderRight: 'none'
                                    },
                                    borderRadius: '10px 10px 0 0', // Rounded top corners
                                    color: '#000000', // Default text color
                                    bgcolor: '#E0E0E0', // Default background color
                                    '&.Mui-selected': {
                                        bgcolor: '#A8C0D0', // Background color when selected
                                        color: '#000000', // Text color when selected

                                    }
                                }
                            }}
                        >
                            <Tab label="My Child" id="tab-0" aria-controls="tabpanel-0" />
                            <Tab label="My Resources" id="tab-1" aria-controls="tabpanel-1" />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabIndex} index={0}>
                        {/* Content for Tab 1 */}
                        <Health />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        {/* Content for Tab 2 */}
                        <Resources />
                    </TabPanel>
                    <Box sx={{flexGrow: 1, mt: 3}} />
                    <Button
                        onClick={handleLogout}
                        sx={{
                            m: '0 10px',
                            p: '8px 20px',
                            color: '#FFFFFF',
                            width: 'auto',
                            backgroundColor: '#607D8B',
                            borderColor: '#000000',
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderRadius: '16px',
                            alignSelf: 'center',
                            '&:hover': {
                                backgroundColor: '#546E7A'
                            }
                        }}
                    >
                        Logout
                    </Button>
                </Paper>
            </Box>
        </div>
    );
}

export default Profile;