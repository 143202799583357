import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthContext} from './AuthContext';

const CheckAuth = ({children}) => {
  const {currentUser} = useContext(AuthContext);
  
  return (
    <>
      { currentUser ? children : <Navigate to="/" replace /> }
    </>
  );
};

export default CheckAuth;