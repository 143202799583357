// Footer.js
import React from 'react';
import {Box, Typography, Button} from '@mui/material';

const Footer = () => {
  return (
    <Box py={1} bgcolor="lightblue" sx={{textAlign: 'center'}}>
      <Typography variant="h6" gutterBottom>
        Our Mission: Empowering Families with Mental Health Resources
      </Typography>
      <Typography variant="body1" paragraph>
        AUXMentis is committed to improving mental health outcomes for children by supporting parents and caregivers with the tools they need.
        Our platform offers personalized resource recommendations, early intervention strategies, and a pathway to better care.
      </Typography>
      <Box>
        <Button
          variant="outlined"
          size="large"
          sx={{margin: '0 10px', borderColor: 'white', color: 'white'}}
          href="https://www.facebook.com/people/Auxmentis/61557744060662/"
          target="_blank"
        >
          Facebook
        </Button>
        <Button
          variant="outlined"
          size="large"
          sx={{margin: '0 10px', borderColor: 'white', color: 'white'}}
          href="https://www.instagram.com/auxmentis/"
          target="_blank"
        >
          Instagram
        </Button>
        <Button
          variant="outlined"
          size="large"
          sx={{margin: '0 10px', borderColor: 'white', color: 'white'}}
          href="https://www.linkedin.com/company/auxmentis"
          target="_blank"
        >
          LinkedIn
        </Button>
      </Box>
      <Typography variant="body2" sx={{mt: 3}}>
        © 2024 AUXMentis. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;