import {Paper, Box} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import {AuthContext} from './AuthContext';
import {signOut} from 'firebase/auth';
import {auth} from '../firebase';
import {useNavigate} from 'react-router-dom';

const UserCheck = () => {
  const navigate = useNavigate();

  const {currentUser} = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      console.log('uid', currentUser.uid);
    } else {
      console.log('user is logged out');
    }
  }, [currentUser]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/');
      console.log('Signed out successfully')
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <Box sx={{
      width: '100%',
      maxWidth: '35rem',
      margin: 'auto',
      my: '10px',
      '@media (max-width: 600px)': {width: '90%', height: '80vh'},
      '@media (min-width: 600px)': {width: '90%', height: '80vh'},
    }}>
      <Paper elevation={3} sx={{
        bgcolor: '#D0E0F0',
        padding: {xs: 1, sm: 2},
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}>
        <p style={{paddingLeft: '30px', marginTop: '30px'}}>
          User ID Check
          <br />
          <br />
          Hello, {currentUser ? currentUser.email : ''}
        </p>
        <p style={{paddingLeft: '30px', marginTop: '25px'}}>
          <button onClick={handleLogout}>
            Logout
          </button>
        </p>
      </Paper>
    </Box>
  );

};

export default UserCheck;