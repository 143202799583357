import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const GoogleSlides = () => {
    const { currentUser, login, authError, clearError } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        clearError();
        login(email, password);
    };

    useEffect(() => {
        document.body.style.backgroundColor = 'lightgray';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {!currentUser ? (
                <div style={{ border: '1px solid #ddd', padding: '20px', borderRadius: '5px', backgroundColor: 'black', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                    <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={{ padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={{ padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                        <button type="submit" style={{ padding: '10px', fontSize: '16px', borderRadius: '4px', cursor: 'pointer', backgroundColor: 'lightgray', color: 'black', border: 'none' }}>
                            Login
                        </button>
                    </form>
                    {authError && <p style={{ color: 'red', marginTop: '10px' }}>{authError}</p>}
                </div>
            ) : (
                <>
                    <div style={{ position: 'fixed', bottom: '45px', left: '25px', zIndex: 1000 }}>
                        {/* Return to Admin Home */}
                        <button
                            onClick={() => navigate('/admin')}
                            style={{
                                padding: '10px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                border: '1px solid #000000',
                                backgroundColor: 'white',
                            }}
                        >
                            Back
                        </button>
                    </div>

                    <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <iframe
                            src="https://docs.google.com/presentation/d/1EaQ8YqjEhA4vkND1yzp__m4OgzpCFQMpmkrkqw7MjNk/embed?start=true&loop=true&delayms=3000"
                            frameBorder="0"
                            style={{ width: '100%', height: '100%', border: 'none' }}
                            allowFullScreen
                            title="Google Slides Presentation"
                        ></iframe>
                    </div>
                </>
            )}
        </div>
    );
};

export default GoogleSlides;