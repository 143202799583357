import React from 'react';
import {Box, Typography, Card, CardActionArea, CardMedia, Paper, CssBaseline} from '@mui/material';
import {Link} from 'react-router-dom';
import NavBar from './Navigation';

const discussionGroups = {
    'Behavioral': {
        path: '/behavioral',
        description: 'This forum is dedicated to exchanging strategies, tools, and insights on managing behavioral challenges in children. It serves as a supportive space for parents and caregivers to share experiences and techniques that promote positive behaviors and emotional regulation.',
        imageUrl: '/behavioral.png'
    },
    'ADHD': {
        path: '/adhd',
        description: 'This forum focuses on sharing strategies, tools, and experiences to support children with ADHD, enhancing their ability to manage daily challenges and improve concentration.',
        imageUrl: '/adhd.png'
    },
    'Anxiety': {
        path: '/anxiety',
        description: 'Explore discussions on techniques and tools to help children manage anxiety. This forum serves as a resource for parents and caregivers seeking support and advice.',
        imageUrl: '/anxiety.png'
    },
    'Depression': {
        path: '/depression',
        description: 'Dedicated to supporting children facing depression, this forum provides insights into therapeutic practices, coping mechanisms, and shared experiences to foster understanding and support.',
        imageUrl: '/depression.png'
    }
};

function DiscussionGroups() {
    return (
        <div>
            <CssBaseline />
            <NavBar />
            <Box sx={{
                width: '100%',
                maxWidth: '35rem',
                margin: 'auto',
                my: '10px',
                '@media (max-width: 600px)': {width: '100%', height: 'auto'},
                '@media (min-width: 600px)': {width: '90%', height: 'auto'},
            }}>
                <Paper elevation={3} sx={{
                    bgcolor: '#D0E0F0',
                    padding: {xs: 2, sm: 3},
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    borderRadius: '5px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                    {Object.entries(discussionGroups).map(([groupName, groupDetails], index) => (
                        <Card key={index} sx={{
                            margin: '10px 0',
                            border: '1px solid #ccc',
                            borderRadius: '25px',
                            backgroundColor: '#e0e0e0',
                            boxShadow: 3,
                            ':hover': {
                                boxShadow: 6,
                            }
                        }}>
                            <CardActionArea component={Link} to={groupDetails.path} sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 1,
                                height: '100%',
                            }}>
                                <Box sx={{
                                    width: '25%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant="h6">{groupName}</Typography>
                                    <CardMedia
                                        component="img"
                                        sx={{width: 50, height: 50, mt: 1}}
                                        image={groupDetails.imageUrl}
                                        alt={`${groupName} Image`}
                                    />
                                </Box>
                                <Typography variant="body1" sx={{
                                    width: '75%',
                                    padding: {xs: 1, sm: 2},
                                }}>
                                    {groupDetails.description}
                                </Typography>
                            </CardActionArea>
                        </Card>
                    ))}
                </Paper>
            </Box>
        </div>
    );
}

export default DiscussionGroups;