import React from 'react';
import {Box, Typography, Paper, Button, CssBaseline} from '@mui/material';

function ResourcePlaceholder() {

    const handleCloseTab = () => {
        window.close();
    };

    return (
        <div>
            <CssBaseline />
            <Box sx={{
                width: '100%',
                maxWidth: '35rem',
                margin: 'auto',
                marginTop: '50px',
                height: '500px'
            }}>
                <Paper elevation={3} sx={{
                    bgcolor: '#D0E0F0',
                    padding: {xs: 2, sm: 3},
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    borderRadius: '5px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                    <Typography variant="h5" gutterBottom>
                        This page is currently set up as a static placeholder to demonstrate the click-through capability of the resource card. In the future, this page will be populated with data associated with the resource you selected.
                    </Typography>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 2}}>
                        <Button
                            variant="contained"
                            onClick={handleCloseTab}
                            sx={{
                                marginTop: '50px',
                                backgroundColor: '#607D8B',
                                borderColor: '#000000',
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderRadius: '16px',
                                '&:hover': {
                                    backgroundColor: '#546E7A',
                                },
                            }}
                        >
                            Close Tab
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </div>
    );
}

export default ResourcePlaceholder;