import React, { useEffect } from 'react';
import { Grid, Box, Typography, Container } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import ContactWidget from './ContactWidget';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

const BusinessPage = () => {

    useEffect(() => {
        // Log a page view event when the component mounts
        logEvent(analytics, 'page_view', {
            page_title: 'Business',
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    return (
        <Container maxWidth="lg">
            {/* Header with Navigation Bar */}
            <Header />

            {/* Section 1: The Impact of Child Mental Health on Employee Productivity */}
            <Box py={5}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom>
                            The Impact of Child Mental Health on Employee Productivity
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Mental health struggles at home, especially concerning a child, can significantly impact employee performance at work. Employees often face distractions, emotional stress, and increased absenteeism, leading to a decline in productivity.
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: '#f0f4fa',
                                padding: 2,
                                borderRadius: '8px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6" fontStyle="italic">
                                “70% of working parents report missing work due to their child’s mental health needs.”
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src="/stressedemployee.png"
                            alt="Stressed Employee"
                            sx={{
                                width: '100%', // Makes the image take up the full width of its container
                                maxWidth: '500px', // Limits the maximum width of the image
                                height: 'auto', // Maintains the image's aspect ratio
                                borderRadius: '8px',
                                mx: 'auto', // Centers the image horizontally
                                display: 'block'
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Section 2: Why Employers Need to Support Their Employees’ Mental Health */}
            <Box py={5} bgcolor="lightgray">
                <Grid container spacing={4} alignItems="flex-start">
                    {/* First Block: Image on Left, Text on Right */}
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src="/happyemployees.png"
                            alt="Happy Employees"
                            sx={{
                                width: '100%',
                                maxWidth: { xs: '90%', md: '100%' }, // Ensure image scales correctly
                                borderRadius: '8px',
                                mx: 'auto',
                                display: 'block',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ pr: { xs: 4, md: 5 }, pl: { xs: 2, md: 0 } }}> {/* Increased padding-right for mobile */}
                            <Typography variant="h4" gutterBottom>
                                Why Employers Need to Support Their Employees’ Mental Health
                            </Typography>
                            <Typography variant="body1" paragraph>
                                By supporting the mental health of employees and their families, businesses can reduce workplace stress, increase employee retention, and improve overall productivity. A workforce that feels supported is more engaged and motivated.
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Second Block: Text on Left, Image on Right */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ pl: { xs: 2, md: 5 }, pr: { xs: 4, md: 0 } }}> {/* Added padding-right for mobile */}
                            <Typography variant="h4" gutterBottom>
                                Breaking the Stress Cycle
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Employees who face ongoing stress from managing their child’s mental health needs are at risk of burnout, absenteeism, and decreased job performance. This strain not only impacts their well-being but also affects overall workplace productivity and morale. AUXMentis helps alleviate this burden by providing AI-driven, personalized mental health resources for their families, offering guidance and support tailored to each child's unique needs. By empowering employees with the tools to navigate these challenges, AUXMentis enables them to focus more effectively on their work while ensuring their child's mental health remains a priority.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: { xs: 4, sm: 6, md: -4 },  // Adjusting margin to move image up or down based on screen size
                                mx: 'auto',
                                position: 'relative',
                                width: '100%',
                                maxWidth: { xs: '100%', md: '500px' }, // Maintain responsive width
                                px: { xs: 2, md: 3 },     // Padding on left/right for mobile/desktop
                                boxSizing: 'border-box',
                                zIndex: 1,
                            }}
                        >
                            <Box
                                component="img"
                                src="/employercosts.png"
                                alt="Employer Costs"
                                sx={{
                                    width: '100%',
                                    maxWidth: { xs: '90%', md: '100%' }, // Ensure the image has a maximum width on mobile
                                    height: 'auto',
                                    borderRadius: '8px',
                                    mb: 1,
                                    mx: 'auto',
                                }}
                            />
                            <Typography
                                variant="caption"
                                sx={{
                                    position: 'absolute',
                                    bottom: '-20px',
                                    right: 0,
                                    fontSize: '0.75rem',
                                    pr: 1,
                                }}
                            >
                                *Evernorth Research Institute 2024
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Section 3: How AUXMentis Helps Businesses and Employees */}
            <Box py={5}>
                <Typography variant="h4" gutterBottom textAlign="center">
                    How AUXMentis Helps Businesses and Employees
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                padding: 3,
                                bgcolor: '#f0f4fa',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6">AI-Driven Recource Navigation</Typography>
                            <Typography variant="body2">
                                Our AI-powered system guides parents to the best resources based on each child’s specific mental health needs.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                padding: 3,
                                bgcolor: '#f0f4fa',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6">Mental Health Care Management</Typography>
                            <Typography variant="body2">
                                AUXMentis helps parents track appointments, medications, and refeills. It also provides a place to track their child's behavior to enable eaiser discussions during appointments.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                padding: 3,
                                bgcolor: '#f0f4fa',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6">Education</Typography>
                            <Typography variant="body2">
                                We make accessing education for employees to better understand mental health, manage stress, and support their children.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Footer */}
            <Footer />

            <ContactWidget />
        </Container>
    );
};

export default BusinessPage;