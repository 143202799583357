import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFunctions} from 'firebase/functions';
import {getAnalytics, logEvent} from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyC0omxjFaAqTihcUYwRDA7q40YHNBiWPN0',
  authDomain: 'auxmentis-chat-prototype.firebaseapp.com',
  projectId: 'auxmentis-chat-prototype',
  storageBucket: 'auxmentis-chat-prototype.appspot.com',
  messagingSenderId: '988166217358',
  appId: '1:988166217358:web:c6f3953a30a81d2eda73df',
  measurementId: 'G-Z2RY1LSLRP'
};
  
 // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const functions = getFunctions(app);
export {analytics, logEvent};
export default app;