import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {AppBar, Toolbar, Typography, Grid, Box, Paper, Avatar, IconButton, Menu, MenuItem, CssBaseline, CardMedia} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavBar from './Navigation';

const anxiety = [
    {id: 1, title: 'Technology Aids for Anxiety Management', description: 'Discussing apps and digital tools that can assist children in coping with anxiety.', user: 'Jack', avatar: '/user12-m.png'},
    {id: 2, title: 'Mindfulness Practices for Children with Anxiety', description: 'Exploring effective mindfulness techniques that can help children manage anxiety.', user: 'Kim', avatar: '/user11-f.png'},
    {id: 3, title: 'Support Groups and Therapies', description: 'Sharing experiences with support groups and therapy sessions for children experiencing anxiety.', user: 'Caroline', avatar: '/user13-f.png'},
    {id: 4, title: 'Home Strategies for Managing Anxiety', description: 'Techniques and tips for parents to help their children manage anxiety at home.', user: 'Garrett M', avatar: '/user14-m.png'},
];

function Anxiety() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div>
                <CssBaseline />
                <NavBar />
                <Box sx={{
                    width: '100%',
                    maxWidth: '35rem',
                    margin: 'auto',
                    my: '10px',
                    '@media (max-width: 600px)': {width: '100%', height: '80vh'},
                    '@media (min-width: 600px)': {width: '90%', height: '80vh'},
                }}>
                    <Paper elevation={3}
                        className="discussion"
                        sx={{
                            bgcolor: '#D0E0F0',
                            padding: {xs: 1, sm: 2},
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}>
                        <AppBar position="static" color="default" sx={{backgroundColor: 'transparent', boxShadow: 'none', color: 'black'}}>
                            <Toolbar>
                                <CardMedia
                                    component="img"
                                    image="/anxiety.png" // Replace with your image path
                                    alt="Behavioral"
                                    sx={{width: 40, height: 40, marginRight: 2}}
                                />
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    Anxiety Discussion
                                </Typography>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="navigation menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose} component={Link} to="/Behavioral">Behavioral</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/ADHD">ADHD</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/Depression">Depression</MenuItem>
                                </Menu>
                            </Toolbar>
                        </AppBar>
                        <Grid>
                            <Box>
                                {anxiety.map((anxiety) => (
                                    <Link to={`/anxiety/${anxiety.id}`} key={anxiety.id} style={{textDecoration: 'none'}}>
                                        <Paper elevation={2} sx={{
                                            margin: '16px 0',
                                            padding: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '25px',
                                            backgroundColor: '#e0e0e0'
                                        }}>
                                            <Avatar src={anxiety.avatar} sx={{marginRight: 2}} />
                                            <Box>
                                                <Typography variant="h6" sx={{fontWeight: 'bold'}}>{anxiety.title}</Typography>
                                                <Typography variant="body2" sx={{color: '#5F7D8E'}}>{` ${anxiety.user}`}</Typography>
                                                <Typography variant="body1">{anxiety.description}</Typography>
                                            </Box>
                                        </Paper>
                                    </Link>
                                ))}
                            </Box>
                        </Grid>
                    </Paper>
                </Box>
            </div >
        </>
    );
}

export default Anxiety;