import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {Box, Typography, Paper, CssBaseline, Avatar, CardMedia} from '@mui/material';
import NavBar from './Navigation';

const anxietyDetails = {
    '1': {
        title: 'Technology Aids for Anxiety Management',
        description: 'Discussing apps and digital tools that can assist children in coping with anxiety.',
        user: 'Jack',
        avatar: '/user12-m.png',
        comments: [
            {user: 'Teresa', message: 'We found that apps with mood tracking features help my child understand and manage their emotions better.', avatar: '/user15-f.png'},
            {user: 'Caroline', message: 'Has anyone tried \'Breathe, Think, Do with Sesame\'? It’s great for younger children.', avatar: '/user13-f.png'},
            {user: 'Kim', message: 'The \'Calm\' app has great features like sleep stories and meditation tailored for different age groups.', avatar: '/user11-f.png'},
            {user: 'Jack', message: 'We use \'Headspace for Kids\' - it helps with focusing and calming down through fun activities.', avatar: '/user12-m.png'},
            {user: 'Garrett M', message: 'I’m looking into biofeedback devices; they seem promising in helping kids become aware of their body\'s responses.', avatar: '/user14-m.png'},
        ]
    },
    '2': {
        title: 'Mindfulness Practices for Children with Anxiety',
        description: 'Exploring effective mindfulness techniques that can help children manage anxiety.',
        user: 'Kim',
        avatar: '/user11-f.png',
        comments: [
            {user: 'Jack', message: 'How young can you start teaching mindfulness to kids? I’m curious if toddlers can learn it too.', avatar: '/user12-m.png'},
            {user: 'Kim', message: 'We\'ve introduced guided meditation sessions at home, and they\'ve been very calming for my child.', avatar: '/user11-f.png'},
            {user: 'Teresa', message: 'We use bedtime stories that incorporate mindfulness themes, and it helps with night-time anxiety.', avatar: '/user15-f.png'},
            {user: 'Caroline', message: 'Yoga has been a game-changer for us; combining movement with breathing exercises helps a lot.', avatar: '/user13-f.png'},
            {user: 'Garrett M', message: 'Does anyone use mindfulness apps designed specifically for kids? Looking for recommendations.', avatar: '/user14-m.png'},
        ]
    },
    '3': {
        title: 'Support Groups and Therapies',
        description: 'Sharing experiences with support groups and therapy sessions for children experiencing anxiety.',
        user: 'Caroline',
        avatar: '/user13-f.png',
        comments: [
            {user: 'Garrett M', message: 'We’ve joined a local anxiety support group which has been great for sharing coping strategies.', avatar: '/user14-m.png'},
            {user: 'Jack', message: 'Can anyone recommend online support groups for parents? Face-to-face meetings are hard to attend.', avatar: '/user12-m.png'},
            {user: 'Caroline', message: 'Art therapy has opened a new way for my child to express feelings they can’t put into words.', avatar: '/user13-f.png'},
            {user: 'Teresa', message: 'Music therapy sessions at our community center have been very soothing for my kids.', avatar: '/user15-f.png'},
            {user: 'Kim', message: 'Finding the right therapist was key for us; it took a few tries, but the right fit makes all the difference.', avatar: '/user11-f.png'},
        ]
    },
    '4': {
        title: 'Home Strategies for Managing Anxiety',
        description: 'Techniques and tips for parents to help their children manage anxiety at home.',
        user: 'Garrett M',
        avatar: '/user14-m.png',
        comments: [
            {user: 'Garrett M', message: 'Visual aids like emotion charts help my children communicate their feelings without stress.', avatar: '/user14-m.png'},
            {user: 'Teresa', message: 'Having regular family meetings to discuss anything that’s bothering us has been beneficial.', avatar: '/user15-f.png'},
            {user: 'Kim', message: 'Routine helps a lot; having a predictable schedule reduces anxiety for my daughter.', avatar: '/user11-f.png'},
            {user: 'Caroline', message: 'Deep breathing exercises are a quick and easy method we practice regularly.', avatar: '/user13-f.png'},
            {user: 'Jack', message: 'We\'ve designated a \'quiet space\' at home where anyone can go if they feel overwhelmed.', avatar: '/user12-m.png'},
        ]
    },
};

function AnxietyDetails() {
    const {anxietyId} = useParams();
    const details = anxietyDetails[anxietyId];

    if (!details) {
        return <Typography variant="h6">Topic not found</Typography>;
    }

    return (
        <div>
            <CssBaseline />
            <NavBar />
            <Box sx={{
                width: '100%',
                maxWidth: '35rem',
                margin: 'auto',
                my: '10px',
                '@media (max-width: 600px)': {width: '100%', height: '80vh'},
                '@media (min-width: 600px)': {width: '90%', height: '80vh'},
            }}>
                <Paper elevation={3}
                    className="discussion"
                    sx={{
                        bgcolor: '#D0E0F0',
                        padding: {xs: 1, sm: 2},
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        borderRadius: '5px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>
                    <Link to="/anxiety" style={{textDecoration: 'none', color: '#5F7D8E', marginBottom: '20px'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <CardMedia
                                component="img"
                                image="/leftarrow.png"
                                alt="Back"
                                sx={{width: 24, height: 24, marginRight: 1}}
                            />
                            <Typography variant="h6" sx={{fontWeight: 'bold', textAlign: 'left'}}>Back to Anxiety Discussion</Typography>
                        </Box>
                    </Link>
                    <Paper elevation={2} sx={{
                        padding: 2,
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '25px',
                        backgroundColor: '#CACACA'
                    }}>
                        <Avatar src={details.avatar} sx={{marginRight: 2}} />
                        <Box>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>{details.title}</Typography>
                            <Typography variant="body2" sx={{color: '#5F7D8E'}}>{` ${details.user}`}</Typography>
                            <Typography variant="body1">{details.description}</Typography>
                        </Box>
                    </Paper>
                    {details.comments.map((comment, index) => (
                        <Paper key={index} elevation={1} sx={{
                            padding: 2,
                            marginTop: 2,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '25px',
                            backgroundColor: '#e0e0e0'
                        }}>
                            <Avatar src={comment.avatar} sx={{marginRight: 2}} />
                            <Box>
                                <Typography variant="subtitle1" sx={{color: '#5F7D8E', fontWeight: 'bold'}}>{comment.user}</Typography>
                                <Typography variant="body2">{comment.message}</Typography>
                            </Box>
                        </Paper>
                    ))}
                </Paper>
            </Box>
        </div>
    );
}

export default AnxietyDetails;