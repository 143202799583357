import React, {createContext, useContext, useState} from 'react';

const OverlayContext = createContext();

export const useOverlay = () => useContext(OverlayContext);

export const OverlayProvider = ({children}) => {
    const [isOverlayVisible, setOverlayVisible] = useState(false);

    const showOverlay = () => setOverlayVisible(true);
    const hideOverlay = () => setOverlayVisible(false);

    return (
        <OverlayContext.Provider value={{isOverlayVisible, showOverlay, hideOverlay}}>
            {children}
        </OverlayContext.Provider>
    );
};