import React, { useState, useEffect } from 'react';
import { Table, Button, Drawer, Form, Input, message, Menu, Dropdown, Spin, Alert } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import axios from 'axios';

const GroupManager = () => {
  const [groups, setGroups] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerMode, setDrawerMode] = useState('');
  const [newGroup, setNewGroup] = useState({});
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/groups');
      setGroups(response.data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      setError('Error fetching groups. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const ActionMenu = ({ record }) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleDrawerOpen('editGroup', record)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleRemoveGroup(record.id)} danger>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleDrawerOpen = (mode, group = {}) => {
    setDrawerMode(mode);
    setNewGroup(group);
    setDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
    setNewGroup({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroup((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (drawerMode === 'addGroup') {
        await axios.post('/api/groups', newGroup);
        message.success('Group added successfully');
      } else if (drawerMode === 'editGroup') {
        await axios.put(`/api/groups/${newGroup.id}`, newGroup);
        message.success('Group updated successfully');
      }
      fetchGroups();
      handleDrawerClose();
    } catch (error) {
      console.error('Error saving group:', error);
      message.error(`Error saving group: ${error.response?.data || error.message}`);
    }
  };

  const handleRemoveGroup = async (id) => {
    try {
      await axios.delete(`/api/groups/${id}`);
      message.success('Group deleted successfully');
      fetchGroups();
    } catch (error) {
      console.error('Error deleting group:', error);
      message.error('Error deleting group');
    }
  };

  const groupColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={<ActionMenu record={record} />} trigger={['click']}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => handleDrawerOpen('addGroup')} style={{ marginBottom: 16 }}>
        Add Group
      </Button>

      {loading ? (
        <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
      ) : error ? (
        <Alert message="Error" description={error} type="error" showIcon style={{ marginBottom: 16 }} />
      ) : groups.length === 0 ? (
        <Alert message="No groups available" type="info" showIcon style={{ marginBottom: 16 }} />
      ) : (
        <Table dataSource={groups} columns={groupColumns} rowKey="id" />
      )}

      <Drawer
        title={drawerMode === 'addGroup' ? 'Add Group' : 'Edit Group'}
        width={480}
        visible={drawerVisible}
        onClose={handleDrawerClose}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} type="primary">
              Save
            </Button>
          </div>
        }
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input name="name" value={newGroup.name || ''} onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Description">
            <Input name="description" value={newGroup.description || ''} onChange={handleInputChange} />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default GroupManager;