import React, { useState, useEffect } from 'react';
import { Table, Button, Drawer, Form, Input, message, Menu, Dropdown, Spin, Alert } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import axios from 'axios';

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerMode, setDrawerMode] = useState('');
  const [newCategory, setNewCategory] = useState({});
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Error fetching categories. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const ActionMenu = ({ record }) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleDrawerOpen('editCategory', record)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleRemoveCategory(record.id)} danger>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleDrawerOpen = (mode, category = {}) => {
    setDrawerMode(mode);
    setNewCategory(category);
    setDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
    setNewCategory({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategory((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (drawerMode === 'addCategory') {
        await axios.post('/api/categories', newCategory);
        message.success('Category added successfully');
      } else if (drawerMode === 'editCategory') {
        await axios.put(`/api/categories/${newCategory.id}`, newCategory);
        message.success('Category updated successfully');
      }
      fetchCategories();
      handleDrawerClose();
    } catch (error) {
      console.error('Error saving category:', error);
      message.error(`Error saving category: ${error.response?.data || error.message}`);
    }
  };

  const handleRemoveCategory = async (id) => {
    try {
      await axios.delete(`/api/categories/${id}`);
      message.success('Category deleted successfully');
      fetchCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
      message.error('Error deleting category');
    }
  };

  const categoryColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={<ActionMenu record={record} />} trigger={['click']}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => handleDrawerOpen('addCategory')} style={{ marginBottom: 16 }}>
        Add Category
      </Button>

      {loading ? (
        <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
      ) : error ? (
        <Alert message="Error" description={error} type="error" showIcon style={{ marginBottom: 16 }} />
      ) : categories.length === 0 ? (
        <Alert message="No categories available" type="info" showIcon style={{ marginBottom: 16 }} />
      ) : (
        <Table dataSource={categories} columns={categoryColumns} rowKey="id" />
      )}

      <Drawer
        title={drawerMode === 'addCategory' ? 'Add Category' : 'Edit Category'}
        width={480}
        visible={drawerVisible}
        onClose={handleDrawerClose}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} type="primary">
              Save
            </Button>
          </div>
        }
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input name="name" value={newCategory.name || ''} onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Description">
            <Input name="description" value={newCategory.description || ''} onChange={handleInputChange} />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default CategoryManager;