import {Box} from '@mui/material';
import React from 'react';
import {messageStyles} from '../styles'; // Ensure this import is correct
import LoadText from './LoadText';

const MessageComponent = ({text, type, onComplete}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: type === 'bot' ? 'row' : 'row-reverse',
            alignItems: 'center',
            justifyContent: 'flex-start',
            my: 1,
            ...messageStyles[type]
        }}>
            {type === 'bot' ? (
                <LoadText
                    text={text}
                    speed={150}
                    onComplete={onComplete}
                />
            ) : (
                <div>{text}</div>  // Directly display the text for user messages
            )}
        </Box>
    );
};

export default React.memo(MessageComponent);