import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const messageStyles = {
  bot: {
    bgcolor: '#e8eaed',
    borderRadius: '20px',
    borderTopLeftRadius: '5px',
    p: 2,
    my: 1,
    width: 'fit-content',
    maxWidth: '80%',
    marginLeft: 1,
    marginRight: 'auto',
    border: '1px solid #b8b9ba',
    display: 'flex',
    alignItems: 'center',
  },
  user: {
    bgcolor: '#35db9f',
    borderRadius: '20px',
    borderTopRightRadius: '5px',
    p: 2,
    my: 1,
    width: 'fit-content',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 1,
    border: '1px solid #208762',
    display: 'flex',
    alignItems: 'center',
  },
  system: {
    bgcolor: '#007BA7', // Dark blue background
    color: '#ffffff', // White text for contrast
    p: 2, // Padding inside the card
    borderRadius: '10px', // Rounded corners
    my: 1, // Margin vertical for spacing
    width: '100%', // Full width for system messages
    maxWidth: '100%', // Ensure it fits in the container
    boxShadow: '0 2px 4px rgba(0,0,0,0.20)', // Optional: adding shadow for depth
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: 1,
    marginLeft: 1,
  },
};

export const ChoiceButton = styled(Button)(({ choiceType, theme }) => ({
  width: '125px',  // Confirm fixed width
  height: '45px',  // Confirm fixed height
  margin: theme.spacing(1),  // Adjust margins if necessary
  padding: '8px 15px', // Internal padding
  color: '#fff',
  backgroundColor: '#607D8B',
  fontWeight: 'bold',
  borderColor: '#607D8B',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#546E7A',
  },
  ...(choiceType === 'Yes' && {
    backgroundColor: '#c5e3c6',
    borderColor: '#c5e3c6',
    '&:hover': {
      backgroundColor: '#a7c9a8',
    },
  }),
  ...(choiceType === 'No' && {
    backgroundColor: '#F8BBD0',
    borderColor: '#F8BBD0',
    '&:hover': {
      backgroundColor: '#F48FB1',
    },
  })
}));