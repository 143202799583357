import { Box, CssBaseline } from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useQuiz } from './QuizContext';
import { useOverlay } from './OverlayContext';

function Navigation() {
  const { currentUser, resetIntroduction } = useContext(AuthContext);
  const { resetQuiz } = useQuiz();
  const { showOverlay, hideOverlay } = useOverlay();

  const handleInternalLinkClick = (event, path) => {
    if (path === '/chat') {
      showOverlay();
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('scrollToBottomEvent'));
        setTimeout(hideOverlay, 700);
      }, 2750);
    }

    if (path === '#reset') {
      event.preventDefault();
      resetIntroduction();
      return;
    }
    if (path === '#refresh') {
      event.preventDefault();
      resetQuiz();
      return;
    }
    if (!currentUser) {
      event.preventDefault();
      alert('You must be logged in to access this page.');
    }
  };

  const navigationItems = [
    { path: '#reset', imgSrc: '/auxmentis.png', alt: 'Auxmentis' },
    { path: '/chat', imgSrc: '/home.png', alt: 'Home' },
    { path: '/profile', imgSrc: '/profile.png', alt: 'Profile' },
    { path: '/community', imgSrc: '/community.png', alt: 'Community' },
    { path: '#refresh', imgSrc: '/reset.png', alt: 'Reset Introduction' }
  ];

  return (
    <div>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '100%',
          maxWidth: '35rem',
          margin: 'auto',
          marginTop: '10px',
          marginBottom: '10px',
          paddingTop: '10px',
          paddingBottom: '5px',
          borderRadius: '15px',
          overflow: 'hidden',
          backgroundColor: '#E0E0E0',
          '@media (max-width: 600px)': { width: '100%' },
          '@media (min-width: 600px)': { width: '90%' }
        }}
      >
        {navigationItems.map((link, index) => (
          <Link key={index} to={link.path} onClick={(e) => handleInternalLinkClick(e, link.path)}>
            <Box
              sx={{
                padding: '8px', // General padding around each image
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: index === 0 ? '12px' : '0px', // Extra space for the first item
                marginRight: index === navigationItems.length - 1 ? '12px' : '0px' // Extra space for the last item
              }}
            >
              <Box
                component="img"
                src={link.imgSrc}
                alt={link.alt}
                sx={{
                  height: index === 0 ? { xs: '45px', sm: '60px' } : { xs: '45px', sm: '55px' },
                  width: index === 0 ? { xs: '45px', sm: '60px' } : { xs: 'auto', sm: 'auto' },
                  maxWidth: '100%', // Ensures image does not exceed its natural width
                  flexGrow: 1
                }}
              />
            </Box>
          </Link>
        ))}
      </Box>
    </div>
  );
}

export default Navigation;