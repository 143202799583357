import {Box, CssBaseline, Paper, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {AuthContext} from './AuthContext';
import Introduction from './Introduction';
import Login from './Login';
import NavBar from './Navigation';
import {useOverlay} from './OverlayContext';
import QuestionManager from './QuestionManager';

function ChatBot() {
  const {currentUser, loading, showIntroduction, setShowIntroduction} = useContext(AuthContext);
  const {isOverlayVisible, showOverlay} = useOverlay();  // Use the overlay controls

  if (loading) {
    showOverlay();  // Show overlay while loading
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <Box sx={{
        width: '100%',
        maxWidth: '35rem',
        margin: 'auto',
        my: '10px',
        position: 'relative',  // Needed for overlay positioning
        '@media (max-width: 600px)': {width: '100%', height: '80vh'},
        '@media (min-width: 600px)': {width: '90%', height: '80vh'},
      }}>
        <Paper elevation={3}
          className="conversation"
          sx={{
            bgcolor: '#D0E0F0',
            padding: {xs: 1, sm: 2},
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            borderRadius: '5px',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}>
          {!currentUser ? (
            <Login />
          ) : (
            <>
              {showIntroduction ? (
                <Introduction setShowIntroduction={setShowIntroduction} />
              ) : (
                <QuestionManager />
              )}
            </>
          )}
        </Paper>
        {isOverlayVisible && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(208, 224, 240, 1)', // Semi-transparent overlay
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}>
            <Typography sx={{
              color: 'black',
              fontSize: '26px',
              textAlign: 'center',
              paddingLeft: {xs: '55px', sm: '65px', md: '75px'},
              paddingRight: {xs: '55px', sm: '65px', md: '75px'}
            }}>
              🔍 One moment while I analyze your previous progress so I can get you back to where you left off.
            </Typography>
            <img src="./loading.gif" alt="Loading" style={{marginLeft: '-30px', marginTop: '20px', width: 100, height: 100}} />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default ChatBot;