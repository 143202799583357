import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {Box, Typography, Paper, CssBaseline, Avatar, CardMedia} from '@mui/material';
import NavBar from './Navigation';

const depressionDetails = {
    '1': {
        title: 'Understanding Childhood Depression',
        description: 'Discussing how to recognize signs of depression in children and the early steps to take.',
        user: 'Alison',
        avatar: '/user16-f.png',
        comments: [
            {user: 'KellyAnn', message: 'I found that keeping a behavior diary helped us identify patterns and discuss them more effectively with our therapist.', avatar: '/user20-f.png'},
            {user: 'Josh', message: 'Sudden changes in sleep patterns and loss of interest in favorite activities were red flags for us.', avatar: '/user17-m.png'},
            {user: 'Scarlette', message: 'It\'s important to communicate openly with your child and not to brush off their feelings as just mood swings.', avatar: '/user18-f.png'},
            {user: 'Alison', message: 'What are some early signs I should watch for? My son has been very withdrawn lately.', avatar: '/user16-f.png'},
            {user: 'Paul', message: 'Does anyone know about resources for professional assessments? It’s hard to make the call on my own.', avatar: '/user19-m.png'},
        ]
    },
    '2': {
        title: 'Activities to Support Children with Depression',
        description: 'Sharing activities that can help children cope with depression and improve their mood.',
        user: 'Josh',
        avatar: '/user17-m.png',
        comments: [
            {user: 'Paul', message: 'Building models or puzzles works well for us; it’s engaging and gives a sense of accomplishment.', avatar: '/user19-m.png'},
            {user: 'Alison', message: 'Art has been a wonderful outlet for my daughter. It allows her to express feelings she can\'t put into words.', avatar: '/user16-f.png'},
            {user: 'Josh', message: 'We’ve started doing family walks; getting outside seems to help lift KellyAnnryone’s spirits.', avatar: '/user17-m.png'},
            {user: 'KellyAnn', message: 'Has anyone tried mindfulness coloring books? They seem to offer a peaceful activity.', avatar: '/user20-f.png'},
            {user: 'Scarlette', message: 'Music therapy can be very soothing, especially playing instruments like drums or a simple keyboard.', avatar: '/user18-f.png'},
        ]
    },
    '3': {
        title: 'Therapeutic Approaches for Depression',
        description: 'Exploring different therapeutic methods for treating depression in young children.',
        user: 'Scarlette',
        avatar: '/user18-f.png',
        comments: [
            {user: 'Paul', message: 'Has anyone used group therapy? I think social interaction might help, but I’m unsure.', avatar: '/user19-m.png'},
            {user: 'Josh', message: 'Play therapy has been beneficial; it helps children express what they’re feeling through play.', avatar: '/user17-m.png'},
            {user: 'Scarlette', message: 'We are looking into family therapy as well. It\'s important for us to learn how we can support our child together.', avatar: '/user18-f.png'},
            {user: 'KellyAnn', message: 'Animal-assisted therapy has done wonders for our family. Our therapist works with dogs and it\'s incredibly calming for my child.', avatar: '/user20-f.png'},
            {user: 'Alison', message: 'Cognitive Behavioral Therapy (CBT) has been recommended to us. Any experiences to share?', avatar: '/user16-f.png'},
        ]
    },
    '4': {
        title: 'Medication and Treatment Options',
        description: 'Information exchange on the use of medication and other treatment options for depression.',
        user: 'Paul',
        avatar: '/user19-m.png',
        comments: [
            {user: 'Scarlette', message: 'We’ve also incorporated supplements like omega-3 which seem to have subtle but positive effects.', avatar: '/user18-f.png'},
            {user: 'Paul', message: 'What should I ask my doctor when considering medication for my child? I want to be well-prepared.', avatar: '/user19-m.png'},
            {user: 'Josh', message: 'It’s crucial to monitor and adjust medications under close supervision; side effects can be challenging.', avatar: '/user17-m.png'},
            {user: 'Alison', message: 'Starting on medication was a tough decision, but it was necessary for us to stabilize mood.', avatar: '/user16-f.png'},
            {user: 'KellyAnn', message: 'Has anyone used St. John’s Wort? I\'ve read it can help with mild depression, but I’m cautious about herbal treatments.', avatar: '/user20-f.png'},
        ]
    },
};

function DepressionDetails() {
    const {depressionId} = useParams();
    const details = depressionDetails[depressionId];

    if (!details) {
        return <Typography variant="h6">Topic not found</Typography>;
    }

    return (
        <div>
            <CssBaseline />
            <NavBar />
            <Box sx={{
                width: '100%',
                maxWidth: '35rem',
                margin: 'auto',
                my: '10px',
                '@media (max-width: 600px)': {width: '100%', height: '80vh'},
                '@media (min-width: 600px)': {width: '90%', height: '80vh'},
            }}>
                <Paper elevation={3}
                    className="discussion"
                    sx={{
                        bgcolor: '#D0E0F0',
                        padding: {xs: 1, sm: 2},
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        borderRadius: '5px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>
                   <Link to="/depression" style={{textDecoration: 'none', color: '#5F7D8E', marginBottom: '20px'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <CardMedia
                                component="img"
                                image="/leftarrow.png"
                                alt="Back"
                                sx={{width: 24, height: 24, marginRight: 1}}
                            />
                            <Typography variant="h6" sx={{fontWeight: 'bold', textAlign: 'left'}}>Back to Depression Discussion</Typography>
                        </Box>
                    </Link>
                    <Paper elevation={2} sx={{
                        padding: 2,
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '25px',
                        backgroundColor: '#CACACA'
                    }}>
                        <Avatar src={details.avatar} sx={{marginRight: 2}} />
                        <Box>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>{details.title}</Typography>
                            <Typography variant="body2" sx={{color: '#5F7D8E',}}>{` ${details.user}`}</Typography>
                            <Typography variant="body1">{details.description}</Typography>
                        </Box>
                    </Paper>
                    {details.comments.map((comment, index) => (
                        <Paper key={index} elevation={1} sx={{
                            padding: 2,
                            marginTop: 2,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '25px',
                            backgroundColor: '#e0e0e0'
                        }}>
                            <Avatar src={comment.avatar} sx={{marginRight: 2}} />
                            <Box>
                                <Typography variant="subtitle1" sx={{color: '#5F7D8E', fontWeight: 'bold'}}>{comment.user}</Typography>
                                <Typography variant="body2">{comment.message}</Typography>
                            </Box>
                        </Paper>
                    ))}
                </Paper>
            </Box>
        </div>
    );
}

export default DepressionDetails;