import SendIcon from '@mui/icons-material/Send';
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, InputAdornment, Link, MenuItem, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { memo, useEffect, useRef, useState } from 'react';
import '../scrollbar.css';
import MessageComponent from './MessageComponent';
import { parseSystemMessage } from './ParseSystemMessages';
import { useQuiz } from './QuizContext';

const QuestionManager = memo(() => {
    const {
        questions,
        setQuestions,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        conversationHistory,
        setConversationHistory,
        score,
        setScore,
        quizFinished,
        setQuizFinished,
        resetQuiz
    } = useQuiz();
    const [openEndedResponse, setOpenEndedResponse] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [cardRefs, setCardRefs] = useState([]);
    const [selectedChoice, setSelectedChoice] = useState('');
    // eslint-disable-next-line
    const [showGif, setShowGif] = useState(false);
    // eslint-disable-next-line
    const [responsesHandled, setResponsesHandled] = useState(false);
    const bottomRef = useRef(null);

    useEffect(() => {
        const handleScrollToBottom = () => {
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        };

        window.addEventListener('scrollToBottomEvent', handleScrollToBottom);

        return () => {
            window.removeEventListener('scrollToBottomEvent', handleScrollToBottom);
        };
    }, []);

    // Effect to add new question to conversation history if it's not already the last entry
    useEffect(() => {
        // Check if the quiz is finished or if questions are not loaded yet
        if (quizFinished || questions.length === 0) {
            console.log('Quiz is finished or questions are not yet available.');
            return;
        }

        // Ensure the current question index is valid
        if (currentQuestionIndex < 0 || currentQuestionIndex >= questions.length) {
            console.log('Current question index is out of bounds:', currentQuestionIndex);
            return;
        }

        const newEntry = { type: 'bot', text: questions[currentQuestionIndex]?.question };
        if (conversationHistory.length === 0 || (conversationHistory[conversationHistory.length - 1]?.text !== newEntry.text && newEntry.text !== undefined)) {
            console.log('Adding new question to history:', newEntry.text);
            setConversationHistory((prev) => [...prev, newEntry]);
        }
    }, [currentQuestionIndex, questions, conversationHistory, setConversationHistory, quizFinished]);

    // Update the refs array to match the number of conversation items
    useEffect(() => {
        setCardRefs((refs) => Array(conversationHistory.length).fill().map((_, i) => refs[i] || React.createRef()));
    }, [conversationHistory.length]);

    useEffect(() => {
        // Scroll to the first new card in the batch after updates
        if (cardRefs[conversationHistory.length - 5] && cardRefs[conversationHistory.length - 5].current) {
            cardRefs[conversationHistory.length - 5].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [cardRefs, conversationHistory.length]);

    const handleChange = (event) => {
        const choice = event.target.value;
        setSelectedChoice(choice);
        const choiceObject = questions[currentQuestionIndex].choices.find((c) => c.text === choice);
        if (choiceObject) {
            handleChoiceClick(choiceObject.weight, choiceObject.text, choiceObject.nextSet);
        }
    };

    // Handle multiple choice question responses
    const handleChoiceClick = (weight, choiceText, nextSet = null) => {
        console.log('Choice clicked', { choiceText, currentQuestionIndex, questionsLength: questions.length });

        // Add user's choice to the conversation history
        setConversationHistory((prev) => [...prev, { type: 'user', text: choiceText }]);
        setScore((prevScore) => prevScore + weight);

        if (currentQuestionIndex < questions.length - 1) {
            // Normal progression: Move to the next question
            setCurrentQuestionIndex((prevIndex) => {
                setSelectedChoice(''); // Clear choice
                return prevIndex + 1;
            });
        } else {
            // Last question or needs to load a new set of questions
            if (nextSet) {
                // Special handling for loading new question sets
                const sets = JSON.parse(localStorage.getItem('questionSets'));
                setQuestions(sets[nextSet]); // Load the new set of questions
                setCurrentQuestionIndex(0); // Reset index for the new set
                setSelectedChoice(''); // Clear choice
            } else {
                // Last question logic, if applicable
                console.log('Last question reached, additional logic here if needed.');
            }
        }
    };

    // Add a new function to show loading info
    const displayLoadingInfo = () => {
        if (submitting) {
            return (
                <Box sx={{
                    position: 'fixed', // Use 'fixed' to position relative to the viewport
                    top: 0, // Align to the top of the viewport
                    left: 0, // Align to the left of the viewport
                    width: '100%', // Full width
                    height: '100%', // Full height
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent backdrop
                    zIndex: 1500 // Ensure it's above other content
                }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: 'white',
                                maxWidth: '500px', // Set your desired width here
                                wordWrap: 'break-word' // Ensures text wraps and does not overflow
                            }}
                        >
                            🔍 Analyzing...I'm carefully reviewing your responses to bring you the best resources that meet your needs. Almost there!</Typography>
                        <img src="./loading.gif" alt="Loading" style={{ marginLeft: '-30px', width: 100, height: 100 }} />
                    </Box>
                </Box>
            );
        }
        return null;
    };

    // Handle submission of open-ended responses
    const handleOpenEndedResponseSubmit = async (e) => {
        e.preventDefault();
        setShowGif(true);
        setSubmitting(true);
        setQuizFinished(true);

        // Log and add the user's response to the history first
        console.log('Adding open-ended response to conversation history.');
        setConversationHistory((prev) => [...prev, { type: 'user', text: openEndedResponse }]);

        console.log('Score before OpenAI call:', score);

        // Proceed with scoring the response
        const keywords = { 'struggle': 3, 'help': 5, 'behavior': 7 };
        const instruction = `As an assistant, your task is to score the input text based on the presence of certain keywords: ${Object.entries(keywords).map(([key, value]) => `"${key}" worth ${value} points`).join(', ')}. Please analyze the following text and return a score based on these keywords.`;

        try {
            await new Promise((resolve) => setTimeout(resolve, 2500));
            const openaiResponse = await axios.post('https://us-central1-auxmentis-prototype-412305.cloudfunctions.net/chat', {
                messages: [{ role: 'system', content: instruction }, { role: 'user', content: openEndedResponse }],
            });

            if (!openaiResponse.data || openaiResponse.status !== 200) {
                throw new Error(`Failed to retrieve valid response from OpenAI API, status code: ${openaiResponse.status}`);
            }

            // Extract the score from the response
            const regex = /(\d+)/;
            const openAiScore = parseInt(openaiResponse.data.choices[0].message.content.match(regex)?.[1], 10) || 0;
            const totalScore = score + openAiScore;

            console.log('Score after OpenAI call:', totalScore);

            const response = await fetch('/responses.json');  // Fetching the scores

            // Check if the response is successful
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON data and assign it to 'responses'
            const responses = await response.json();  // Set 'responses' to the parsed data

            const suitableResponse = responses.find((r) => totalScore >= r.min_score && totalScore <= r.max_score);

            // After scoring the response and right before displaying results
            if (suitableResponse) {
                // Determine the bot message based on the variable from suitableResponse
                let botMessage;
                switch (suitableResponse.text) {
                    case 'bhvl':
                        botMessage = 'These resources, centered on Behavioral issues, should to address your current needs.';
                        break;
                    case 'adhd':
                        botMessage = 'These resources focused on ADHD should meet your current needs.';
                        break;
                    case 'axty':
                        botMessage = 'The following Anxiety resources are tailored to meet your current needs.';
                        break;
                    case 'dpsn':
                        botMessage = 'These Depression related resources are well-suited to meet your current needs.';
                        break;
                    default:
                        botMessage = 'Here are some resources and information based on your responses:';
                        break;
                }

                // Add a bot message to explain what the results are
                setConversationHistory((prev) => [...prev, { type: 'bot', text: botMessage }]);

                // Add a pause before showing the results
                setTimeout(async () => {
                    const apiResponse = await axios.get('https://api.auxmentis.com/get-links', { params: { variable: suitableResponse.text } });
                    if (apiResponse.data && apiResponse.data.length > 0) {
                        apiResponse.data.forEach((item) => {
                            setConversationHistory((prev) => [...prev, { type: 'system', text: `Title: ${item.title}, Description: ${item.description}, Insight: ${item.insight}, URL: ${item.url}, Image: ${item.image}` }]);
                        });
                    } else {
                        setConversationHistory((prev) => [...prev, { type: 'system', text: 'No response from the server.' }]);
                    }
                }, 3000);
            } else {
                setConversationHistory((prev) => [...prev, { type: 'system', text: 'No suitable response found for your score.' }]);
            }

            // Only mark the quiz as finished after all these operations
            setQuizFinished(true);
            setResponsesHandled(true); // This triggers the useEffect to finish the quiz
            setShowGif(false);  // Hide loading GIF
            setSubmitting(false);  // Stop submitting
        } catch (error) {
            setShowGif(false);  // Ensure loading GIF is hidden on error
            setSubmitting(false);  // Ensure submitting is reset on error
            console.error('An error occurred:', error);
            setConversationHistory((prev) => [...prev, { type: 'system', text: 'Error processing your response.' }]);
        } finally {
            setOpenEndedResponse('');
            setSubmitting(false);
            setShowGif(false);
        }
    };

    // Set flag when the page is about to be unloaded
    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.setItem('resetOnLoad', 'true');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Check flag on component mount and reset quiz if needed
    useEffect(() => {
        if (localStorage.getItem('resetOnLoad') === 'true') {
            resetQuiz();
            localStorage.removeItem('resetOnLoad'); // Clear the flag after resetting
        }
    }, [resetQuiz]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box className='conversation' sx={{ flexGrow: 1, p: 2 }}>
                {conversationHistory.map((item, index) => {
                    const { title, description, insight, url, image } = parseSystemMessage(item.text);
                    return (
                        <Box key={index} ref={cardRefs[index]} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1,
                            justifyContent: item.type === 'user' ? 'flex-end' : 'flex-start',
                            width: '100%'
                        }}>
                            {item.type === 'bot' && (
                                <Avatar src="/chatavatar.png" sx={{ mr: 1, width: 32, height: 32 }} />
                            )}
                            {item.type === 'system' ? (
                                <Link
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Card
                                        sx={{
                                            backgroundColor: '#e0e0e0',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            textAlign: 'left',
                                            marginBottom: '15px',
                                            borderRadius: '10px',
                                            width: '100%',
                                            maxWidth: '100%',
                                            flexGrow: 1,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Subtle shadow for depth
                                        }}
                                    >
                                        {/* Move Box content into CardHeader */}
                                        <CardHeader
                                            avatar={
                                                <img
                                                    src={`/${image}`}
                                                    alt="Resource"
                                                    style={{
                                                        width: '60px', // Adjusted size for header
                                                        height: '60px',
                                                        borderRadius: '10px',
                                                    }}
                                                />
                                            }
                                            title={title}
                                            sx={{
                                                backgroundColor: '#f5f5f5', // Light background for the header
                                                padding: '10px',
                                                '& .MuiCardHeader-title': {
                                                    fontSize: '1.1rem', // Adjust font size for title
                                                    fontWeight: 'bold', // Make the title stand out
                                                },
                                            }}
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: '#000000',
                                                    marginBottom: '15px', // Space below the description
                                                }}
                                            >
                                                {description}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: '#000000',
                                                    marginTop: '10px', // Space above the insight
                                                    padding: '10px', // Add padding for spacing
                                                    backgroundColor: '#f0f0f0', // Slightly lighter gray
                                                    borderRadius: '8px', // Rounded corners
                                                }}
                                            >
                                                <strong>Clinical Insight:</strong> {insight}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Link>
                            ) : (
                                <MessageComponent
                                    text={item.text}
                                    type={item.type}
                                    onComplete={() => item.type === 'user' && index === conversationHistory.length - 1}
                                />
                            )}
                        </Box>
                    );
                })}
                <div ref={bottomRef}></div>
                {displayLoadingInfo()}
            </Box>
            {!submitting && !quizFinished && (
                <Divider sx={{ bgcolor: '#8a8a8a', height: '2px', mx: -2, my: 2 }} />
            )}
            <Box sx={{ p: 2 }}>
                {!quizFinished && questions.length > 0 && (
                    <>
                        {questions[currentQuestionIndex]?.type === 'open-ended' ? (
                            <Box component="form" onSubmit={handleOpenEndedResponseSubmit}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Your response"
                                    multiline
                                    rows={2}
                                    value={openEndedResponse}
                                    onChange={(e) => setOpenEndedResponse(e.target.value)}

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton type="submit" aria-label="send">
                                                    <SendIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        mb: 1,
                                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '30px',
                                            bgcolor: 'white',
                                            border: '2px solid #8a8a8a',
                                            '&:hover': { border: '2px solid #636363' },
                                            '&.Mui-focused': { borderColor: '#A8B8C8' },
                                        },
                                        '& .MuiInputLabel-outlined': {
                                            '&.MuiInputLabel-shrink': { transform: 'translate(14px, -20px) scale(0.75)' },
                                            color: '#6C7A8A',
                                        },
                                        flex: 1, // Allows the input to fill available space
                                        '& .MuiInputBase-root': {
                                            paddingRight: 0, // Adjust padding to align the input field with the icon
                                        },
                                        '& .MuiInputLabel-root': {
                                            transform: 'translate(14px, 27px) scale(1)', // Adjust label position if needed
                                        },
                                        '& .MuiInputLabel-shrink': {
                                            transform: 'translate(14px, -6px) scale(0.75)', // Adjust shrink label position if needed
                                        },
                                    }} />
                            </Box>
                        ) : (
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        key={currentQuestionIndex} // Adding key to force remount
                                        fullWidth
                                        select
                                        value={selectedChoice || ''} // Ensuring controlled component
                                        onChange={handleChange}
                                        variant="outlined"
                                        displayEmpty
                                        sx={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.0)',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#8a8a8a',
                                                borderWidth: '2px',
                                                borderRadius: '25px',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#636363',
                                                borderWidth: '2px',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#A8B8C8',
                                                    borderWidth: '2px',
                                                }
                                            },
                                        }}
                                        SelectProps={{
                                            displayEmpty: true,
                                            MenuProps: {
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: 'rgba(168, 184, 200, 0.95)',
                                                        borderRadius: '25px',
                                                        boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                                                    }
                                                },
                                                anchorOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                },
                                                transformOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }}
                                    >
                                        {/* Placeholder item */}
                                        <MenuItem value="" disabled>
                                            Please select an option
                                        </MenuItem>
                                        {/* Map through choices for the current question */}
                                        {questions[currentQuestionIndex]?.choices?.map((choice, index) => (
                                            <MenuItem key={index} value={choice.text}>
                                                {choice.text}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
});

export default QuestionManager;