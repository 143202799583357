import React, { useEffect, useRef } from 'react';
import { Container, Typography, Box, Link, Button } from '@mui/material';
import { styled } from '@mui/system';
import Header from './Header';
import Footer from './Footer';
import ContactWidget from './ContactWidget';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

const AboutUs = () => {
    // Refs for smooth scrolling to sections
    const aboutRef = useRef(null);
    const storyRef = useRef(null);
    const visionRef = useRef(null);
    const statusRef = useRef(null);

    // Scroll to the respective section
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    useEffect(() => {
        // Log a page view event when the component mounts
        logEvent(analytics, 'page_view', {
            page_title: 'About',
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    return (
        <Container maxWidth="lg">
            {/* Header with Navigation Bar */}
            <Header />

            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                {/* Floating Sidebar */}
                <Sidebar>
                    <Button onClick={() => scrollToSection(aboutRef)}>About AUXMentis</Button>
                    <Button onClick={() => scrollToSection(storyRef)}>Our Story</Button>
                    <Button onClick={() => scrollToSection(visionRef)}>Our Vision</Button>
                    <Button onClick={() => scrollToSection(statusRef)}>Current Status</Button>
                </Sidebar>

                {/* Main Content */}
                <Container maxWidth="md" sx={{ marginLeft: '150px', padding: '2rem', lineHeight: '1.8' }}>
                    {/* About AUXMentis */}
                    <Box ref={aboutRef} sx={{ marginBottom: '3rem' }}>
                        <DropCap>A</DropCap>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '2rem' }}>
                            UXMentis was founded with a singular mission: to ease the often overwhelming burden of finding resources and managing mental health care for children. Led by <Link href="https://www.linkedin.com/in/peterfeinberg" target="_blank">Peter Feinberg</Link>, who brings over 25 years of expertise in product development, AUXMentis is supported by a group of passionate volunteers who share this commitment. In 2022, Peter decided to deepen his understanding of clinical informatics, motivated by a desire to integrate technology, information, and clinical expertise in ways that would transform healthcare—making it more accessible, less frustrating, and easier to navigate. He enrolled in Stanford’s <Link href="https://med.stanford.edu/master-clinical-informatics-management.html" target="_blank">Master of Clinical Informatics Management (MCiM)</Link> program, where the concept of AUXMentis was born as part of his practicum project.
                        </Typography>
                    </Box>

                    {/* Our Story */}
                    <Box ref={storyRef} sx={{ marginBottom: '3rem' }}>
                        <Heading>Our Story</Heading>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '1.5rem' }}>
                            Peter and his wife Briana, a nurse at Stanford Health Care, faced the arduous journey of seeking effective mental health care for one of their children, who was diagnosed with ADHD and anxiety. What they hoped would be a straightforward process turned into a prolonged battle filled with setbacks. The first doctors they met with were quick to suggest medications, bypassing any conversations about therapy or behavioral interventions that Peter and Briana believed might help their daughter find more long-term stability. This focus on medication felt like a shortcut, missing the deeper understanding and care their child needed. Yet, as they searched for other options, they faced the harsh reality that many providers weren’t even seeing new patients let alone accepting insurance.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '1.5rem' }}>
                            When they finally would find a doctor able to see her, the wait time stretched months into the future. And once they did get in, there were many times when the match just wasn’t right. Doctors came and went, some unable to connect with her in the way she needed. It was devastating to restart building trust and hope, only to find it wasn’t the right fit and start the search all over again.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '1.5rem' }}>
                            Their frustration peaked when they discovered a resource that seemed perfect—a group with both the qualifications and the empathy they’d been hoping for. But that hope was shattered by the exorbitant cost. The group didn’t accept insurance, and the hourly rate was simply beyond their reach, adding to the mounting stress, frustration, and a sense of helplessness.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '1.5rem' }}>
                            All these obstacles took a toll on their family. The continuous disappointments, the financial worries, and the emotional strain created friction at home, testing Peter and Briana’s resilience. The weight of managing their child’s needs affected them both deeply, impacting their mental health, and added a level of stress that seeped into their work lives.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '2rem' }}>
                            What Peter and Briana endured left them with a profound awareness of how challenging it is for parents to navigate mental health care. The path was grueling, but through their struggle, they found a purpose—to turn their experiences into something that could help others, hoping to create a world where parents and children could find the care they need without facing the same painful obstacles.
                        </Typography>
                    </Box>

                    {/* Our Vision */}
                    <Box ref={visionRef} sx={{ marginBottom: '3rem' }}>
                        <Heading>Our Vision</Heading>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '1.5rem' }}>
                            AUXMentis addresses a fundamental gap in mental health care: connecting families with the right resources at the right time. Despite a strong background in healthcare, it still took Peter and Briana years to find the right diagnosis and effective treatment for their child. The vast amount of information online often left them more overwhelmed than informed. This experience led Peter to create a streamlined platform that combines clinical expertise with AI, helping parents and caregivers quickly find tailored mental health resources for their children.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '2rem' }}>
                            Mental health care in the U.S. urgently needs reform. The stress parents face in seeking proper diagnoses, resources, and care not only impacts a child’s development but also affects family well-being and job performance. AUXMentis bridges this gap by offering accessible, customized solutions that help families navigate mental health care, drawing on a comprehensive selection of resources from across the mental health field. These recommendations connect families with the best options available, rather than relying solely on AUXMentis’s own offerings. Over the past two years, Peter has devoted himself to researching innovative approaches, consulting with educators and mental health professionals, and developing a prototype that has the potential to help millions and drive the transformative change so desperately needed in mental health care.
                        </Typography>
                    </Box>

                    {/* Current Status */}
                    <Box ref={statusRef} sx={{ marginBottom: '3rem' }}>
                        <Heading>Current Status</Heading>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '1.5rem' }}>
                            At AUXMentis, we've invested thousands of hours into research, interviews, and collaboration with mental health professionals, educators, and families. These efforts have culminated in a working prototype—a demo that embodies our commitment to making mental health care navigation simpler and more effective for parents and caregivers.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ marginBottom: '2rem' }}>
                            Our mission has already sparked strong interest from various organizations, educators, and groups dedicated to supporting children with mental health needs, many of whom are eager to partner with us in pilot programs. We are currently in the pre-seed funding phase and actively seeking investment to bring AUXMentis to more families in need. If you're interested in partnering with us, joining a pilot program, or investing in a platform poised to make a meaningful difference, please <Link href="/contact" underline="always">reach out</Link>. Together, we can help redefine the future of mental health care for children.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            {/* Footer */}
            <Footer />

            <ContactWidget />
        </Container>
    );
};

// Styled Sidebar with fixed position
const Sidebar = styled(Box)(({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 150,
    width: '180px',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.divider}`,
}));

export default AboutUs;

// Styled Components for the custom styling
const DropCap = styled('span')({
    fontSize: '4rem',
    float: 'left',
    lineHeight: '1',
    paddingRight: '0.3rem',
    fontFamily: 'serif', // Specify a serif font for the drop cap
});

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2.5), // Increased margin to add space between heading and text
    fontFamily: 'serif', // Set headings to a serif font for a classic look
}));