import {Button, TextField, Typography, Box, IconButton, InputAdornment} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React, {useContext, useState} from 'react';
import {AuthContext} from './AuthContext';
import LoadText from './LoadText';
import {inputFieldStyling} from './InputFieldStyling';
import {messageStyles} from '../styles';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stage, setStage] = useState('email');
  const {login, authError, clearError} = useContext(AuthContext);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setStage('password');
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  const handleSubmit = stage === 'email' ? handleEmailSubmit : handlePasswordSubmit;

  const handleTryAgain = () => {
    clearError();
    setEmail('');
    setPassword('');
    setStage('email');
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '80vh'}}>
      <div>
        {/* Original welcome text */}
        <Typography sx={messageStyles.bot}>
          <LoadText text={stage === 'email' ? 'Welcome to AUXMentis, please enter your username to begin.' : 'Next, enter your password'} speed={150} />
        </Typography>

        {/* Error message with Try Again button */}
        {authError && (
          <Typography sx={messageStyles.error}>
            Sorry, your username or password is incorrect.
            <Button onClick={handleTryAgain} sx={{ml: 1}}>
              Try Again
            </Button>
          </Typography>
        )}

        <Typography sx={{backgroundColor: '#E3F2FD', padding: '10px', borderRadius: '8px', mt: 3, mb: 2, fontSize: '0.9rem', color: '#0D47A1', textAlign: 'center'}}>
          If you do not have a username and are interested in learning more about AUXMentis, {' '}
          <a href="/contact" style={{color: '#1e88e5', textDecoration: 'none'}}>Contact Us</a>.
        </Typography>

        <Typography sx={{textAlign: 'center', mt: 2, mb: 2}}>
          <Button href="/" sx={{color: '#1e88e5', fontWeight: 'bold'}}>
            Return Home
          </Button>
        </Typography>
      </div>

      {/* Input field container */}
      <Box component="form" onSubmit={handleSubmit} sx={{bgcolor: '#D0E0F0', display: 'flex', alignItems: 'center', mb: 1}}>
        <TextField
          fullWidth
          id={stage === 'email' ? 'email-input' : 'password-input'}
          label={stage === 'email' ? 'Username' : 'Password'}
          type={stage === 'email' ? 'email' : 'password'}
          value={stage === 'email' ? email : password}
          onChange={stage === 'email' ? (e) => setEmail(e.target.value) : (e) => setPassword(e.target.value)}
          required
          sx={inputFieldStyling}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" aria-label="send">
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </div>
  );
};

export default Login;