import React, {useState, useEffect, useRef} from 'react';

const LoadText = ({text, speed = 1000, onComplete}) => {
  const [displayedText, setDisplayedText] = useState('');
  const words = text.split(' ');
  const [index, setIndex] = useState(0);
  const textRef = useRef(null);

  useEffect(() => {
    // Reset when text changes
    setDisplayedText('');
    setIndex(0);
  }, [text]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (index < words.length) {
        setDisplayedText((prev) => prev + (prev.length > 0 ? ' ' : '') + words[index]);
        setIndex((prev) => prev + 1);
      } else {
        clearInterval(timer);
        if (onComplete) {
          onComplete();  // Call onComplete prop after all words are displayed
        }
      }
    }, speed);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [index, words.length, speed, onComplete]);

  useEffect(() => {
    // Scroll into view whenever displayedText updates
    if (textRef.current) {
      textRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [displayedText]);

  return <div ref={textRef}>{displayedText}</div>;
};

export default LoadText;