export const inputFieldStyling = {
    mb: 1,
    '& .MuiOutlinedInput-notchedOutline': {border: 'none'},
    '& .MuiOutlinedInput-root': {
      borderRadius: '30px',
      bgcolor: 'white',
      border: '2px solid #8a8a8a',
      '&:hover': {border: '2px solid #636363'},
      '&.Mui-focused': {borderColor: '#A8B8C8'},
    },
    '& .MuiInputLabel-outlined': {
      '&.MuiInputLabel-shrink': {transform: 'translate(14px, -20px) scale(0.75)'},
      color: '#6C7A8A',
    },
    flex: 1, // Allows the input to fill available space
    '& .MuiInputBase-root': {
      paddingRight: 0, // Adjust padding to align the input field with the icon
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 18px) scale(1)', // Adjust label position if needed
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjust shrink label position if needed
    },
  };