/**
 * Parses the text of a system message to extract title, description, URL, image, and insight.
 * Assumes the system message text is formatted with labels followed by colons.
 * @param {string} text - formatted string from system message.
 * @returns {Object} Parsed message with title, description, URL, image, and insight.
 */
export function parseSystemMessage(text) {
  console.log('Parsing system message:', text); // Log the raw text for debugging

  try {
    const titleRegex = /Title: (.*?), Description:/;
    const descriptionRegex = /Description: (.*?), Insight:/;
    const insightRegex = /Insight: (.*?), URL:/; // Regex to match the insight field
    const urlRegex = /URL: (.*?), Image:/;
    const imageRegex = /Image: (.*)$/;

    const titleMatch = titleRegex.exec(text);
    const descriptionMatch = descriptionRegex.exec(text);
    const insightMatch = insightRegex.exec(text); // Match insight
    const urlMatch = urlRegex.exec(text);
    const imageMatch = imageRegex.exec(text);

    return {
      title: titleMatch ? titleMatch[1].trim() : 'No Title Provided',
      description: descriptionMatch ? descriptionMatch[1].trim() : 'No Description Provided',
      insight: insightMatch ? insightMatch[1].trim() : 'No Insight Provided', // Return insight
      url: urlMatch ? urlMatch[1].trim() : '#',
      image: imageMatch ? imageMatch[1].trim() : 'default-image.png'
    };
  } catch (error) {
    console.error('Error parsing system message:', error);
    return {
      title: 'Error',
      description: 'Failed to parse message content.',
      insight: 'No Insight Provided', // Default insight if parsing fails
      url: '#',
      image: 'default-image.png'
    };
  }
}