import { Paper, Box } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeSlug from 'rehype-slug';

function OpenAiChat() {

    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // To track if we're waiting for a response

    const navigate = useNavigate();
   
    const sendMessage = async (e) => {
        e.preventDefault();  // Prevent form default behavior (page reload)
        setIsLoading(true);  // Indicate that we are waiting for a response

        try {
            const response = await axios.post(
                'https://us-central1-auxmentis-prototype.cloudfunctions.net/chat',
                {
                    messages: [
                        {
                            role: 'system',
                            content: 'You are a helpful assistant that responds in Markdown format. Use **bold**, *italics*, and [links](URL) where appropriate.'
                        },
                        {
                            role: 'user',
                            content: message
                        }
                    ]
                }
            );

            const gptResponse = response.data.choices[0].message.content;
            setChatHistory([...chatHistory, { role: 'user', content: message }, { role: 'gpt', content: gptResponse }]);
            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);  // Once we get a response or error, indicate we're no longer waiting
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // prevent the default behavior (newline)
            sendMessage(e); // send the message
        }
    };

    return (
        <Box sx={{
            width: '100%',
            maxWidth: '35rem',
            margin: 'auto',
            my: '10px',
            '@media (max-width: 600px)': { width: '90%', height: '80vh' },
            '@media (min-width: 600px)': { width: '90%', height: '80vh' },
        }}>
            <Paper elevation={3} sx={{
                bgcolor: '#D0E0F0',
                padding: { xs: 1, sm: 2 },
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                borderRadius: '5px',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}>
                <div style={{ position: 'fixed', bottom: '45px', left: '25px', zIndex: 1000 }}>
                    {/* Return to Admin Home */}
                    <button
                        onClick={() => navigate('/admin')}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            border: '1px solid #000000',
                            backgroundColor: 'white',
                        }}
                    >
                        Back
                    </button>
                </div>

                <div style={{ paddingLeft: '30px' }}>
                    <h1>OpenAI API Test Interface</h1>
                    <div style={{ minHeight: '300px', width: '50%', border: '1px solid black', padding: '10px', marginBottom: '30px' }}>
                        {chatHistory.map((chat, index) => (
                            <div key={index} style={{ marginBottom: '10px', textAlign: chat.role === 'user' ? 'right' : 'left' }}>
                                <ReactMarkdown
                                    children={chat.content}
                                    skipHtml={false}
                                    allowDangerousHtml={false}
                                    linkTarget="_blank"
                                    unwrapDisallowed={true}
                                    rehypePlugins={[rehypeSlug]}
                                    remarkPlugins={[remarkBreaks]}
                                />
                            </div>
                        ))}
                    </div>
                    <form onSubmit={sendMessage}>
                        <textarea
                            placeholder="Enter your prompt..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeyDown}
                            rows="5"
                            style={{ width: '40%', marginRight: '10px', resize: 'vertical' }}
                        />
                        <button type="submit">Submit</button>
                    </form>
                    {isLoading && <div style={{ marginTop: '10px', color: 'blue' }}>Sending...</div>}
                </div>
            </Paper>
        </Box>
    );
}

export default OpenAiChat;
