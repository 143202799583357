import React, { useEffect } from 'react';
import { Grid, Box, Typography, Container } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import ContactWidget from './ContactWidget';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

const ParentsAssistancePage = () => {

    useEffect(() => {
        // Log a page view event when the component mounts
        logEvent(analytics, 'page_view', {
            page_title: 'Parents',
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    return (
        <Box
            sx={{
                bgcolor: 'white', // Ensures background is white
                minHeight: '100vh', // Ensures it covers the full viewport height
            }}
        >
            <Container maxWidth="lg">
                {/* Header with Navigation Bar */}
                <Header />

                {/* Section 1: Emotional and Logistical Challenges */}
                <Box py={5} mb={5}> {/* Added margin-bottom here to space out sections */}
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    backgroundColor: '#f0f4fa',
                                    padding: 3,
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="h4" fontStyle="italic">
                                    "I was completely overwhelmed trying to find the right support for my child."
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom>
                                Emotional and Logistical Challenges
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ul>
                                        <li>
                                            <Typography variant="body1">Confusion about treatment options</Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">Difficulty navigating insurance</Typography>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={6}>
                                    <ul>
                                        <li>
                                            <Typography variant="body1">Time constraints for appointments</Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">Emotional stress and uncertainty</Typography>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                {/* Section 2: The Role of AI in Mental Health Support */}
                <Box py={5} bgcolor="lightgray">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                src="/process.png"
                                alt="AI Process Flowchart"
                                width="100%"
                                sx={{ borderRadius: '8px' }}
                                marginTop={1}
                                marginLeft={1}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom>
                                The Role of AI in Mental Health Support
                            </Typography>
                            <Typography variant="body1" paragraph>
                                AUXMentis leverages AI to analyze your child's specific mental health needs and recommend the most appropriate resources. The AI works in three major steps:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        <strong>AI-Driven Assessments:</strong> Our AI assesses your child's symptoms and environment to understand their unique situation.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <strong>Personalized Resource Matching:</strong> Based on clinical guidelines, the AI matches your family with the best therapists, support groups, or educational content.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <strong>Real-time Feedback:</strong> Track your child's progress and adjust resources as needed to ensure their continued growth and well-being.
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Box>

                {/* Section 3: How AUXMentis Works for Parents */}
                <Box py={5}>
                    <Typography variant="h4" gutterBottom textAlign="center">
                        How AUXMentis Works for Parents
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    padding: 3,
                                    bgcolor: '#f0f4fa',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Typography variant="h6">Step 1</Typography>
                                <Typography variant="body2">
                                    Answer guided questions about your child's symptoms, background, and environment.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    padding: 3,
                                    bgcolor: '#f0f4fa',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Typography variant="h6">Step 2</Typography>
                                <Typography variant="body2">
                                    Receive personalized resource recommendations tailored to your needs.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    padding: 3,
                                    bgcolor: '#f0f4fa',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Typography variant="h6">Step 3</Typography>
                                <Typography variant="body2">
                                    Track your child's progress and adjust their care plan as necessary.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* Footer */}
                <Footer />

                <ContactWidget />
            </Container>
        </Box>
    );
};

export default ParentsAssistancePage;