import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Box, Container, Modal, Button, IconButton } from '@mui/material';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CloseIcon from '@mui/icons-material/Close';
import Header from './Header';
import Footer from './Footer';
import ContactWidget from './ContactWidget';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GeneralInfoPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [autoplay, setAutoplay] = useState(true);
    const sliderRef = useRef(null);

    useEffect(() => {
        // Log a page view event when the component mounts
        logEvent(analytics, 'page_view', {
            page_title: 'General',
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    // Settings for the carousel
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoplay,
        autoplaySpeed: 5000,
        arrows: false,
        pauseOnHover: true,
    };

    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);
    const toggleAutoplay = () => setAutoplay(!autoplay);

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    return (
        <Container maxWidth="lg">
            {/* Header with Navigation Bar */}
            <Header />

            {/* Hero Section */}
            <Box
                sx={{
                    textAlign: 'center',
                    py: 8,
                    mt: 2,
                    bgcolor: '#f0f4fa',
                    backgroundImage: 'url(/womanwithchild.png)', // Your background image
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                {/* Hero Text */}
                <Typography variant="h2" gutterBottom>
                    Understanding the Mental Health Crisis in Children
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Navigating the Complexities of Care for Parents and Caregivers
                </Typography>
                <Typography variant="h6" sx={{ mt: 3 }}>
                    Mental Health Support Shouldn’t Be a Maze
                </Typography>
            </Box>

            {/* Main Content */}
            <Box py={5}>
                {/* Section 1: The State of Children's Mental Health in the U.S. */}
                <Typography variant="h4" gutterBottom>
                    The State of Children's Mental Health in the U.S.
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" paragraph>
                            Over the past decade, mental health challenges among children have skyrocketed. Conditions such as anxiety, depression, and ADHD have become more prevalent, exacerbated by the stressors of modern life. The COVID-19 pandemic has further intensified these issues, with many children facing increased isolation, uncertainty, and disruption to their daily routines.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Despite the growing need for mental health support, access to pediatric mental health services remains limited. Long wait times, a shortage of specialized providers, and high costs present significant barriers for families seeking help. According to recent data, more than 1 in 5 children in the U.S. experience a mental health disorder each year, yet less than half receive the care they need.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src="/numbers.png"
                            alt="Mental Health Statistics"
                            width="100%"
                            sx={{ borderRadius: '8px' }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box py={5} bgcolor="lightgray">
                <Box sx={{ pl: 3 }}>
                    {/* Section 2: The Struggle for Parents and Caregivers */}
                    <Typography variant="h4" gutterBottom>
                        The Struggle for Parents and Caregivers
                    </Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ pl: 3 }}>
                            <Typography variant="body1" paragraph>
                                For parents and caregivers, finding the right mental health resources for their child can feel like an uphill battle. The healthcare system is fragmented, and navigating between insurance, service providers, and treatment options is time-consuming and confusing. Families are often left feeling overwhelmed by the complexity of the system.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Additionally, the cost of care can be prohibitive. Therapy, medication, and specialist appointments all add up, and not all families have access to insurance that covers these services. Even when care is accessible, long waiting lists can delay critical intervention, increasing the emotional toll on both the child and the caregiver.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                padding: 3,
                                mr: 3,
                                marginLeft: 1,
                                mt: 3,
                                borderRadius: '8px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="body1" fontStyle="italic">
                                "It feels like a never-ending search to find the right help for my child, and time is not on our side. Every day that passes without the right support feels like another day lost."
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box
                py={5}
                sx={{
                    mt: 5,
                    border: '2px solid #000000', // Adjust the border width, style, and color
                    borderRadius: '8px',         // Add rounded corners (optional)
                    padding: 3,                  // Add padding inside the box for spacing
                    pl: 3,                       // Ensure left padding is retained
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle shadow for depth
                }}
            >
                <Box sx={{ pl: 3 }}>
                    {/* Section 3: Mental Health and Socioeconomic Status */}
                    <Typography variant="h4" gutterBottom>
                        Mental Health and Socioeconomic Status
                    </Typography>
                </Box>

                <Box sx={{ pl: 3 }}>
                    <Typography variant="body1" paragraph>
                        Mental health challenges don't discriminate based on income, education, or social standing. Whether you're from an affluent community or facing financial hardships, mental health struggles can impact anyone at any stage of life. These challenges can be equally overwhelming, regardless of one's resources or status. What truly matters is understanding, empathy, and access to the right support. At AUXMentis, we recognize that every family's journey is unique. Click below to view some of these journeys.
                    </Typography>
                </Box>

                {/* Link to open the carousel modal */}
                <Box textAlign="center" mt={4}>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        Journeys
                    </Button>
                </Box>
            </Box>

            <Box py={5}>
                {/* Section 4: Why AUXMentis? */}
                <Typography variant="h4" gutterBottom>
                    Why AUXMentis?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" paragraph>
                            AUXMentis is not another mental health treatment option in the already overwhelming sea of mental health tools, it is a revolutionary platform designed to guide parents and caregivers through the often overwhelming landscape of mental health resources. Unlike traditional solutions, AUXMentis acts as a personalized navigator, combining the power of clinical expertise with cutting-edge AI to help parents and caregivers identify the most relevant resources for their child's unique needs. Whether you're looking for local therapists, online support groups, or educational content, AUXMentis tailors its recommendations to your situation. It doesn't just stop at providing options; it empowers them to manage and gain a deeper understanding of their child's mental health condition.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            By analyzing factors such as your child's symptoms, your location, and your insurance coverage, AUXMentis provides a personalized and accessible roadmap for navigating the mental health care landscape. Our goal is to empower families to find the right support at the right time, without unnecessary delays or confusion.
                        </Typography>
                        <Button variant="contained" size="large" sx={{ mt: 2, bgcolor: '#1e88e5' }} href="/parents">
                            For Parents
                        </Button>
                        <Button variant="contained" size="large" sx={{ mt: 2, ml: 2, bgcolor: '#1e88e5' }} href="/business">
                            For Businesses
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src="/process.png"
                            alt="AUXMentis Process"
                            width="100%"
                            sx={{ borderRadius: '8px' }}
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Modal for the Carousel */}
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="carousel-modal-title"
                aria-describedby="carousel-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '800px', // Ensure the modal doesn't exceed 800px
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        outline: 'none',
                    }}
                >
                    {/* Close Button */}
                    <Box textAlign="right">
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Carousel */}
                    <Slider ref={sliderRef} {...settings}>
                        <Box>
                            <img
                                src="/slide1.png"
                                alt="Slide 1"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    maxHeight: '80vh', // Ensure image doesn't overflow vertically
                                }}
                            />
                        </Box>
                        <Box>
                            <img
                                src="/slide2.png"
                                alt="Slide 2"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    maxHeight: '80vh', // Ensure image doesn't overflow vertically
                                }}
                            />
                        </Box>
                        <Box>
                            <img
                                src="/slide3.png"
                                alt="Slide 3"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    maxHeight: '80vh', // Ensure image doesn't overflow vertically
                                }}
                            />
                        </Box>
                    </Slider>

                    {/* Control Buttons */}
                    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                        {/* Previous Arrow */}
                        <IconButton onClick={goToPrevSlide} color="primary">
                            <ArrowBackIosIcon />
                        </IconButton>

                        {/* Play/Pause Button */}
                        <IconButton onClick={toggleAutoplay} color="primary" sx={{ mx: 2 }}>
                            {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>

                        {/* Next Arrow */}
                        <IconButton onClick={goToNextSlide} color="primary">
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Modal>

            {/* Footer */}
            <Footer />

            <ContactWidget />
        </Container>
    );
};

export default GeneralInfoPage;