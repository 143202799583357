import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {AppBar, Toolbar, Typography, Grid, Box, Paper, Avatar, IconButton, Menu, MenuItem, CssBaseline, CardMedia} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavBar from './Navigation';

const behavioral = [
    {id: 1, title: 'Nutritional Strategies for Behaviorial Issues', description: 'Sharing insights on how diet can impact behavior.', user: 'Bob M', avatar: '/user10-m.png'},
    {id: 2, title: 'Effective Time Management Tools for Behaviorial Issues', description: 'Discussing the best tools and apps to help manage behavior-related time management challenges.', user: 'Toni', avatar: '/user6-f.png'},
    {id: 3, title: 'Educational Support for Children with Behaviorial Issues', description: 'A space to exchange information on tutoring and educational programs tailored for behavior issues.', user: 'Carol', avatar: '/user8-f.png'},
    {id: 4, title: 'Behavioral Coaching Techniques', description: 'Exploring different coaching methods and their effectiveness for children with behavior issues.', user: 'Dave', avatar: '/user7-m.png'},
];

function Behavioral() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div>
                <CssBaseline />
                <NavBar />
                <Box sx={{
                    width: '100%',
                    maxWidth: '35rem',
                    margin: 'auto',
                    my: '10px',
                    '@media (max-width: 600px)': {width: '100%', height: '80vh'},
                    '@media (min-width: 600px)': {width: '90%', height: '80vh'},
                }}>
                    <Paper elevation={3}
                        className="discussion"
                        sx={{
                            bgcolor: '#D0E0F0',
                            padding: {xs: 1, sm: 2},
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}>
                        <AppBar position="static" color="default" sx={{backgroundColor: 'transparent', boxShadow: 'none', color: 'black'}}>
                            <Toolbar>
                            <CardMedia
                                    component="img"
                                    image="/behavioral.png" // Replace with your image path
                                    alt="Behavioral"
                                    sx={{width: 40, height: 40, marginRight: 2}}
                                />
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    Behavioral Discussion
                                </Typography>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="navigation menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose} component={Link} to="/ADHD">ADHD</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/Anxiety">Anxiety</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/Depression">Depression</MenuItem>
                                </Menu>
                            </Toolbar>
                        </AppBar>
                        <Grid>
                            <Box>
                                {behavioral.map((behavioral) => (
                                    <Link to={`/behavioral/${behavioral.id}`} key={behavioral.id} style={{textDecoration: 'none'}}>
                                        <Paper elevation={2} sx={{
                                            margin: '16px 0',
                                            padding: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '25px',
                                            backgroundColor: '#e0e0e0'
                                        }}>
                                            <Avatar src={behavioral.avatar} sx={{marginRight: 2}} />
                                            <Box>
                                                <Typography variant="h6" sx={{fontWeight: 'bold'}}>{behavioral.title}</Typography>
                                                <Typography variant="body2" sx={{color: '#5F7D8E'}}>{` ${behavioral.user}`}</Typography>
                                                <Typography variant="body1">{behavioral.description}</Typography>
                                            </Box>
                                        </Paper>
                                    </Link>
                                ))}
                            </Box>
                        </Grid>
                    </Paper>
                </Box>
            </div >
        </>
    );
}

export default Behavioral;