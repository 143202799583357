import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form, Input, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

// Set the base URL for Axios to point to the backend server
axios.defaults.baseURL = 'https://auxmentis-chat-prototype.wl.r.appspot.com';

const { Option } = Select;

const ResourceManager = () => {
    const [resources, setResources] = useState([]);
    const [categories, setCategories] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [limit, setLimit] = useState(10);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState('');
    const [newItem, setNewItem] = useState({});
    const [showResources, setShowResources] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showGroups, setShowGroups] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    useEffect(() => {
        if (modalVisible && (modalType === 'addResource' || modalType === 'editResource')) {
            fetchCategoriesAndGroups();
        }
    }, [modalVisible, modalType]);

    const fetchCategoriesAndGroups = async () => {
        try {
            const categoriesResponse = await axios.get('/api/categories');
            setCategories(categoriesResponse.data);
            const groupsResponse = await axios.get('/api/groups');
            setGroups(groupsResponse.data);
        } catch (error) {
            console.error('Error fetching categories or groups:', error);
            message.error('Error fetching categories or groups');
        }
    };

    const fetchResources = async (limit = 10) => {
        try {
            clearDisplay();
            const response = await axios.get(`/api/resources?limit=${limit}`);

            // Access the data array from the response
            if (Array.isArray(response.data.data)) {
                setResources(response.data.data);
            } else {
                console.error('Expected an array in response.data.data but got:', response.data.data);
                message.error('Unexpected response format');
            }

            setShowResources(true);
        } catch (error) {
            console.error('Error fetching resources:', error);
            message.error('Error fetching resources');
        }
    };


    const fetchCategories = async () => {
        try {
            clearDisplay();
            const response = await axios.get('/api/categories');
            setCategories(response.data);
            setShowCategories(true);
        } catch (error) {
            console.error('Error fetching categories:', error);
            message.error('Error fetching categories');
        }
    };

    const fetchGroups = async () => {
        try {
            clearDisplay();
            const response = await axios.get('/api/groups');
            setGroups(response.data);
            setShowGroups(true);
        } catch (error) {
            console.error('Error fetching groups:', error);
            message.error('Error fetching groups');
        }
    };

    const clearDisplay = () => {
        setShowResources(false);
        setShowCategories(false);
        setShowGroups(false);
        setResources([]);
        setCategories([]);
        setGroups([]);
    };

    const handleLimitChange = (value) => {
        setLimit(value);
        fetchResources(value); // Fetch resources based on the new limit
    };

    const handleOpenModal = async (type) => {
        await fetchCategoriesAndGroups(); // Fetch categories and groups before opening the modal
        setModalType(type);
        setNewItem({});
        setModalVisible(true);
    };

    const handleModalOk = async () => {
        if (modalType === 'addResource' || modalType === 'editResource') {
            if (!newItem.name || !newItem.description || !newItem.url) {
                message.error('Please fill in all required fields for the resource');
                return;
            }
        } else if ((modalType === 'addCategory' || modalType === 'addGroup') && (!newItem.name || !newItem.description)) {
            message.error('Please fill in the name field');
            return;
        }

        try {
            if (modalType === 'addResource') {
                console.log('Adding new resource:', newItem);
                await axios.post('/api/resources', newItem);
                fetchResources(limit);
            } else if (modalType === 'editResource') {
                console.log('Editing resource:', newItem);
                const { id, ...updatedData } = newItem;
                await axios.put(`/api/resources/${id}`, updatedData);
                fetchResources(limit);
            } else if (modalType === 'addCategory') {
                console.log('Adding new category:', newItem);
                await axios.post('/api/categories', newItem);
                fetchCategories();
            } else if (modalType === 'addGroup') {
                console.log('Adding new group:', newItem);
                await axios.post('/api/groups', newItem);
                fetchGroups();
            }
            setModalVisible(false);
        } catch (error) {
            console.error('Error saving item:', error);
            message.error(`Error saving item: ${error.response?.data || error.message}`);
        }
    };

    const handleModalCancel = () => {
        setNewItem({});
        setModalVisible(false);
    };

    const handleRemoveItem = async (type, id) => {
        try {
            if (type === 'resource') {
                await axios.delete(`/api/resources/${id}`);
                fetchResources(limit);
            } else if (type === 'category') {
                await axios.delete(`/api/categories/${id}`);
                fetchCategories();
            } else if (type === 'group') {
                await axios.delete(`/api/groups/${id}`);
                fetchGroups();
            }
        } catch (error) {
            console.error('Error removing item:', error);
            message.error('Error removing item');
        }
    };

    const handleUpload = async (info) => {
        try {
            const formData = new FormData();
            formData.append('file', info.file);
            await axios.post('/api/upload', formData);
            message.success('File uploaded successfully');
            fetchResources(limit);
            fetchCategories();
            fetchGroups();
        } catch (error) {
            console.error('Upload failed:', error);
            message.error('Upload failed');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewItem((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditItem = async (item) => {
        await fetchCategoriesAndGroups(); // Fetch categories and groups before opening the modal
        setModalType('editResource');
        setNewItem({
            id: item.id,
            name: item.name,
            description: item.description,
            url: item.url,
            weight: item.weight,
            category_ids: item.categories ? item.categories.map((cat) => cat.id) : [],
            group_ids: item.groups ? item.groups.map((group) => group.id) : [],
        });
        setModalVisible(true);
    };

    const resourceColumns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Description', dataIndex: 'description', key: 'description' },
        { title: 'URL', dataIndex: 'url', key: 'url' },
        { title: 'Weight', dataIndex: 'weight', key: 'weight' },
        {
            title: 'Categories',
            key: 'categories',
            render: (_, record) => Array.isArray(record.categories) ? record.categories.map((cat) => cat.name).join(', ') : '',
        },
        {
            title: 'Groups',
            key: 'groups',
            render: (_, record) => Array.isArray(record.groups) ? record.groups.map((group) => group.name).join(', ') : '',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button onClick={() => handleEditItem(record)}>Edit</Button>
                    <Button onClick={() => handleRemoveItem('resource', record.id)} style={{ marginLeft: '8px' }}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    const categoryColumns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Description', dataIndex: 'description', key: 'description' },
        {
            title: 'Actions', key: 'actions', render: (_, record) => (
                <Button onClick={() => handleRemoveItem('category', record.id)}>Delete</Button>
            )
        }
    ];

    const groupColumns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Description', dataIndex: 'description', key: 'description' },
        {
            title: 'Actions', key: 'actions', render: (_, record) => (
                <Button onClick={() => handleRemoveItem('group', record.id)}>Delete</Button>
            )
        }
    ];

    return (
        <div>
            <h2>Resource Manager</h2>
            <div>
                <Select defaultValue={10} onChange={handleLimitChange} style={{ width: 120 }}>
                    {[10, 20, 30, 40, 50, 75, 100].map((value) => (
                        <Option key={value} value={value}>{value}</Option>
                    ))}
                </Select>
                <Button onClick={() => handleOpenModal('addResource')}>Add Resource</Button>
                <Button onClick={() => handleOpenModal('addCategory')}>Add Category</Button>
                <Button onClick={() => handleOpenModal('addGroup')}>Add Group</Button>
                <Button onClick={fetchResources}>Search All Resources</Button>
                <Button onClick={fetchCategories}>Search All Categories</Button>
                <Button onClick={fetchGroups}>Search All Groups</Button>
                <Button onClick={clearDisplay}>Clear Display</Button>
                <Upload customRequest={handleUpload}>
                    <Button icon={<UploadOutlined />}>Upload CSV</Button>
                </Upload>
            </div>

            {showResources && (
                <div>
                    <h3>Resources</h3>
                    <Table
                        dataSource={resources}
                        columns={resourceColumns}
                        rowKey='id'
                    />
                </div>
            )}

            {showCategories && (
                <div>
                    <h3>Categories</h3>
                    <Table
                        dataSource={categories}
                        columns={categoryColumns}
                        rowKey='id'
                    />
                </div>
            )}

            {showGroups && (
                <div>
                    <h3>Groups</h3>
                    <Table
                        dataSource={groups}
                        columns={groupColumns}
                        rowKey='id'
                    />
                </div>
            )}

            <Modal
                title={
                    modalType === 'addResource'
                        ? 'Add Resource'
                        : modalType === 'editResource'
                            ? 'Edit Resource'
                            : modalType === 'addCategory'
                                ? 'Add Category'
                                : 'Add Group'
                }
                visible={modalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form layout='vertical'>
                    {modalType === 'addResource' || modalType === 'editResource' ? (
                        <>
                            <Form.Item label='Name'>
                                <Input
                                    name='name'
                                    value={newItem.name || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item label='Description'>
                                <Input
                                    name='description'
                                    value={newItem.description || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item label='URL'>
                                <Input
                                    name='url'
                                    value={newItem.url || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item label='Weight'>
                                <Input
                                    name='weight'
                                    value={newItem.weight || ''}
                                    onChange={handleInputChange}
                                    type='number'
                                />
                            </Form.Item>
                            <Form.Item label='Categories'>
                                <Select
                                    mode='multiple'
                                    name='category_ids'
                                    value={newItem.category_ids || []}
                                    onChange={(value) => setNewItem((prev) => ({ ...prev, category_ids: value }))}
                                    placeholder='Select categories'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {categories.map((category) => (
                                        <Option key={category.id} value={category.id}>
                                            {category.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Groups'>
                                <Select
                                    mode='multiple'
                                    name='group_ids'
                                    value={newItem.group_ids || []}
                                    onChange={(value) => setNewItem((prev) => ({ ...prev, group_ids: value }))}
                                    placeholder='Select groups'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {groups.map((group) => (
                                        <Option key={group.id} value={group.id}>
                                            {group.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item label='Name'>
                                <Input
                                    name='name'
                                    value={newItem.name || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item label='Description'>
                                <Input
                                    name='description'
                                    value={newItem.description || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
        </div>
    );
};

export default ResourceManager;