import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  DatabaseOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import ResourceManager from './ResourceManager'; // Import ResourceManager
import CategoryManager from './CategoryManager'; // Create and Import CategoryManager
import GroupManager from './GroupManager'; // Create and Import GroupManager
import { useNavigate } from 'react-router-dom';

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeSection, setActiveSection] = useState('resources');

  const handleMenuClick = ({ key }) => {
    setActiveSection(key);
  };

  const navigate = useNavigate();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        <div className="logo" style={{ color: 'white', textAlign: 'center', padding: '16px' }}>
          {!collapsed ? 'Resource Manager' : ''}
        </div>
        <Menu theme="dark" defaultSelectedKeys={['resources']} mode="inline" onClick={handleMenuClick}>
          <Menu.Item key="resources" icon={<DatabaseOutlined />}>
            Resources
          </Menu.Item>
          <Menu.Item key="categories" icon={<UserOutlined />}>
            Categories
          </Menu.Item>
          <Menu.Item key="groups" icon={<UserOutlined />}>
            Groups
          </Menu.Item>
          <Menu.Item key="settings" icon={<SettingOutlined />}>
            Settings
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0 }}>
          <div style={{ textAlign: 'right', paddingRight: 24 }}>
            Welcome to AUXMentis!
          </div>
        </Header>
        <Content style={{ margin: '16px' }}>
          {activeSection === 'resources' && <ResourceManager />}
          {activeSection === 'categories' && <CategoryManager />}
          {activeSection === 'groups' && <GroupManager />}
          {activeSection === 'settings' && <div>Settings coming soon!</div>}
        </Content>
        <div style={{ position: 'fixed', bottom: '60px', left: '10px', display: 'flex', gap: '10px' }}>
          {/* Retrun to Admin Home */}
          <button
            onClick={() => navigate('/admin')}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              cursor: 'pointer',
              border: '1px solid #000000',
              backgroundColor: 'white',
              zIndex: 1000,
            }}
          >
            Back
          </button>
        </div>
      </Layout>
    </Layout>
  );
};

export default Dashboard;