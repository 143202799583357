import {Paper, Box} from '@mui/material';
import {createUserWithEmailAndPassword} from 'firebase/auth';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth} from '../firebase';

const Signup = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                navigate('/login')
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });


    }

    return (
        <Box sx={{
            width: '100%',
            maxWidth: '35rem',
            margin: 'auto',
            my: '10px',
            '@media (max-width: 600px)': {width: '90%', height: '80vh'},
            '@media (min-width: 600px)': {width: '90%', height: '80vh'},
        }}>
            <Paper elevation={3} sx={{
                bgcolor: '#D0E0F0',
                padding: {xs: 1, sm: 2},
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                borderRadius: '5px',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}>
                <div>
                    <h1> AUXMentis </h1>
                    <form>
                        <div>
                            <label htmlFor="email-address">
                                Email address
                            </label>
                            <input
                                type="email"
                                label="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder="Email address"
                            />
                        </div>

                        <div>
                            <label htmlFor="password">
                                Password
                            </label>
                            <input
                                type="password"
                                label="Create password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Password"
                            />
                        </div>

                        <button
                            type="submit"
                            onClick={onSubmit}
                        >
                            Sign up
                        </button>
                    </form>

                </div>
            </Paper>
        </Box>
    )
}

export default Signup