import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Fab,
  CircularProgress,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CloseIcon from '@mui/icons-material/Close';

const ContactWidget = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormData({ name: '', email: '', phone: '', message: '' });
    setStatus('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus("Sending...");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, access_key: "568c3bf3-d43a-46b0-8dfa-876482ad2478" }),
      });
      const result = await response.json();

      if (result.success) {
        setStatus("Message sent successfully!");
        setTimeout(handleClose, 1500);
      } else {
        setStatus("Failed to send the message.");
      }
    } catch (error) {
      setStatus("Error sending the message.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Fab
        aria-label="contact"
        onClick={handleOpen}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: '#1e88e5',
          color: 'white',
          '&:hover': {
            backgroundColor: '#1565c0',
          },
        }}
      >
        {/* Override aria-hidden and focusable attributes on the icon */}
        <ContactMailIcon aria-hidden="false" focusable="true" />
      </Fab>


      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            position: 'fixed',
            right: 16,
            m: 0,
            width: 350,
            borderRadius: 2,
          },
        }}
      >
        {/* Header with close button */}
        <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 3, textAlign: 'center', position: 'relative' }}>
          <Typography variant="h5" component="div">
            How can we help?
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.5, mt: 1 }}>
            We usually respond in a few hours
          </Typography>

          {/* Close button in the top right */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ bgcolor: '#f5f5f5', px: 3 }}>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              margin="dense"
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: 1, mb: 2 }}
            />
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              margin="dense"
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: 1, mb: 2 }}
            />
            <TextField
              fullWidth
              label="Phone Number (Optional)"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: 1, mb: 2 }}
            />
            <TextField
              fullWidth
              label="Your Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              multiline
              rows={4}
              margin="dense"
              variant="outlined"
              sx={{ bgcolor: 'white', borderRadius: 1, mb: 2 }}
            />

            {/* Display either a status message or buttons */}
            <DialogActions sx={{ px: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {loading ? (
                <CircularProgress color="primary" size={24} />
              ) : status ? (
                <Typography
                  variant="body2"
                  color={status.includes("successfully") ? "green" : "red"}
                  sx={{ mt: 2, textAlign: 'center' }}
                >
                  {status}
                </Typography>
              ) : (
                <>
                  <Button type="submit" color="primary">
                    Send Message
                  </Button>
                </>
              )}

              {/* Show retry button only on failure */}
              {status && !status.includes("successfully") && !loading && (
                <Button onClick={handleSubmit} color="primary" sx={{ mt: 1 }}>
                  Try Again
                </Button>
              )}
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContactWidget;