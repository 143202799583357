import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {Box, Typography, Paper, CssBaseline, Avatar, CardMedia} from '@mui/material';
import NavBar from './Navigation';

const adhdDetails = {
    '1': {
        title: 'Effective Time Management Tools for ADHD',
        description: 'Discussing the best tools and apps to help manage ADHD-related time management challenges.',
        user: 'Jessica M',
        avatar: '/user1-f.png',
        comments: [
            {user: 'Jessica M', message: 'I’ve found that using apps like \'30/30\' really helps in keeping tasks structured!', avatar: '/user1-f.png'},
            {user: 'Doug', message: 'Has anyone tried \'Due\'? It has reminders that keep nagging you till you get things done.', avatar: '/user2-m.png'},
            {user: 'Terri S', message: 'I recommend \'Trello\' for managing bigger projects and dividing them into smaller tasks.', avatar: '/user4-f.png'},
            {user: 'Jessie\'s Dad', message: 'Don\'t forget about the traditional pomodoro technique, sometimes the simplest methods are the most effective.', avatar: '/user3-m.png'},
            {user: 'Sarah', message: 'Using \'Google Keep\' has been a game changer for quick notes and to-do lists.', avatar: '/user5-f.png'},
        ]
    },
    '2': {
        title: 'Nutritional Strategies for ADHD',
        description: 'Sharing insights on how diet can impact ADHD symptoms and behavior.',
        user: 'Doug',
        avatar: '/user2-m.png',
        comments: [
            {user: 'Jessica M', message: 'Omega-3 fatty acids are crucial! They\'ve helped improve focus in my kids significantly.', avatar: '/user1-f.png'},
            {user: 'Doug', message: 'We’ve cut out sugar completely and noticed a dramatic decrease in hyperactivity.', avatar: '/user2-m.png'},
            {user: 'Terri S', message: 'Is gluten-free a viable option? I’ve read mixed reviews about its effects on ADHD.', avatar: '/user4-f.png'},
            {user: 'Jessie\'s Dad', message: 'Adding protein to breakfast has made mornings much smoother in our house.', avatar: '/user3-m.png'},
            {user: 'Sarah', message: 'Don\'t overlook hydration; it plays a big role in how well our brain functions.', avatar: '/user5-f.png'},
        ]
    },
    '3': {
        title: 'Educational Support for Children with ADHD',
        description: 'A space to exchange information on tutoring and educational programs tailored for ADHD.',
        user: 'Terri S',
        avatar: '/user4-f.png',
        comments: [
            {user: 'Jessica M', message: 'We\'ve had great success with tutors who specialize in ADHD. Patience and understanding their needs is key.', avatar: '/user1-f.png'},
            {user: 'Doug', message: 'Can anyone recommend online resources that cater specifically to ADHD learners?', avatar: '/user2-m.png'},
            {user: 'Terri S', message: 'Look into \'Khan Academy\' for structured, pace-controlled learning paths.', avatar: '/user4-f.png'},
            {user: 'Jessie\'s Dad', message: 'Interactive learning tools like \'Quizlet\' can make studying more engaging for ADHD kids.', avatar: '/user3-m.png'},
            {user: 'Sarah', message: 'Does anyone use \'BrainPOP\'? It offers animated educational videos that my kids find very entertaining and informative.', avatar: '/user5-f.png'},
        ]
    },
    '4': {
        title: 'ADHD Coaching Techniques',
        description: 'Exploring different coaching methods and their effectiveness for children and adults with ADHD.',
        user: 'Jessie\'s Dad',
        avatar: '/user3-m.png',
        comments: [
            {user: 'Jessica M', message: 'We’ve started using mindfulness exercises, and it has helped with impulse control.', avatar: '/user1-f.png'},
            {user: 'Doug', message: 'Behavioral therapy has been part of our approach, especially with setting clear expectations.', avatar: '/user2-m.png'},
            {user: 'Terri S', message: 'I’m curious about cognitive behavioral therapy. Has anyone experienced significant changes with it?', avatar: '/user4-f.png'},
            {user: 'Jessie\'s Dad', message: 'Coaching that focuses on organizational skills can reduce daily stresses for ADHD individuals.', avatar: '/user3-m.png'},
            {user: 'Sarah', message: 'It’s important to celebrate small victories, it helps build confidence and momentum.', avatar: '/user5-f.png'},
        ]
    },
};

function ADHDDetails() {
    const {adhdId} = useParams();
    const details = adhdDetails[adhdId];

    if (!details) {
        return <Typography variant="h6">Topic not found</Typography>;
    }

    return (
        <div>
            <CssBaseline />
            <NavBar />
            <Box sx={{
                width: '100%',
                maxWidth: '35rem',
                margin: 'auto',
                my: '10px',
                '@media (max-width: 600px)': {width: '100%', height: '80vh'},
                '@media (min-width: 600px)': {width: '90%', height: '80vh'},
            }}>
                <Paper elevation={3}
                    className="discussion"
                    sx={{
                        bgcolor: '#D0E0F0',
                        padding: {xs: 1, sm: 2},
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        borderRadius: '5px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>
                    <Link to="/adhd" style={{textDecoration: 'none', color: '#5F7D8E', marginBottom: '20px'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <CardMedia
                                component="img"
                                image="/leftarrow.png"
                                alt="Back"
                                sx={{width: 24, height: 24, marginRight: 1}}
                            />
                            <Typography variant="h6" sx={{fontWeight: 'bold', textAlign: 'left'}}>Back to ADHD Discussion</Typography>
                        </Box>
                    </Link>
                    <Paper elevation={2} sx={{
                        padding: 2,
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '25px',
                        backgroundColor: '#CACACA'
                    }}>
                        <Avatar src={details.avatar} sx={{marginRight: 2}} />
                        <Box>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>{details.title}</Typography>
                            <Typography variant="body2" sx={{color: '#5F7D8E'}}>{` ${details.user}`}</Typography>
                            <Typography variant="body1">{details.description}</Typography>
                        </Box>
                    </Paper>
                    {details.comments.map((comment, index) => (
                        <Paper key={index} elevation={1} sx={{
                            padding: 2,
                            marginTop: 2,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '25px',
                            backgroundColor: '#e0e0e0'
                        }}>
                            <Avatar src={comment.avatar} sx={{marginRight: 2}} />
                            <Box>
                                <Typography variant="subtitle1" sx={{color: '#5F7D8E', fontWeight: 'bold'}}>{comment.user}</Typography>
                                <Typography variant="body2">{comment.message}</Typography>
                            </Box>
                        </Paper>
                    ))}
                </Paper>
            </Box>
        </div>
    );
}

export default ADHDDetails;