import React, {createContext, useState, useEffect} from 'react';
import {getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import app from '../firebase.js'; // Ensure this imports your Firebase app configuration

export const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const auth = getAuth(app);
  const [currentUser, setCurrentUser] = useState(null);
  const [authError, setAuthError] = useState('');
  const [loading, setLoading] = useState(true); // Initialize loading as true to handle initial load

  // Move useState for showIntroduction inside AuthProvider
  const [showIntroduction, setShowIntroduction] = useState(() => {
    // Retrieve the showIntroduction state from local storage to persist across sessions
    const saved = localStorage.getItem('showIntroduction');
    return saved ? JSON.parse(saved) : true; // Default to true if nothing is stored
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false); // Set loading to false once Firebase returns the auth state
    });
    return () => unsubscribe(); // Cleanup the listener on unmount
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Save showIntroduction state to local storage whenever it changes
    localStorage.setItem('showIntroduction', JSON.stringify(showIntroduction));
  }, [showIntroduction]);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setAuthError(error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const clearError = () => {
    setAuthError(''); // Clear error messages
  };

  const resetIntroduction = () => {
    setShowIntroduction(true); // Reset the introduction state
    localStorage.removeItem('showIntroduction'); // Clear the stored state from local storage
  };

  return (
    <AuthContext.Provider value={{
      currentUser,
      login,
      logout,
      authError,
      clearError,
      loading,
      showIntroduction,
      setShowIntroduction,
      resetIntroduction
    }}>
      {loading ? (
        <div>Loading...</div> // Loading indicator while checking authentication state
      ) : (
        children // Render children only when not loading
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;