import React, {useState, useEffect} from 'react';
import {Paper, Box, Button, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, TextField, Divider, Select, MenuItem, FormControl, InputLabel, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {httpsCallable} from 'firebase/functions';
import {onAuthStateChanged} from 'firebase/auth';
import {functions, auth} from '../firebase';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {useNavigate} from 'react-router-dom';

const FirebaseAdmin = () => {
    const [users, setUsers] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('viewer');
    // eslint-disable-next-line
    const [currentUser, setCurrentUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    // State for modals
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                user.getIdTokenResult().then((idTokenResult) => {
                    setIsAdmin(!!idTokenResult.claims.admin);
                    if (!idTokenResult.claims.admin) {
                        setUsers([{uid: user.uid, email: user.email, displayName: user.displayName || ''}]);
                    }
                });
            } else {
                setCurrentUser(null);
                setIsAdmin(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const fetchUsers = async () => {
        console.log('Fetching users...'); // Log when the function is called
        try {
          const getUsersFunction = httpsCallable(functions, 'getUsers');
          const result = await getUsersFunction();
          console.log('Fetched Users Result:', result.data); // Log the result
          setUsers(result.data.users);
          alert('Users fetched successfully');
        } catch (error) {
          console.error('Error fetching users:', error); // Log any errors
          alert(error.message);
        }
      };       

    const handleCreateUser = async (e) => {
        e.preventDefault();
        if (!isAdmin) return alert('You do not have permission to create a new user.');

        try {
            const createUserWithRoleFunction = httpsCallable(functions, 'createUserWithRole');
            const result = await createUserWithRoleFunction({email, password, role});
            alert(result.data.message);
            fetchUsers();
            setEmail('');
            setPassword('');
            setRole('viewer');
        } catch (error) {
            console.error('Error creating user:', error);
            alert(error.message);
        }
    };

    const handleUpdateEmail = async () => {
        try {
            const updateUserEmailFunction = httpsCallable(functions, 'updateUserEmail');
            const result = await updateUserEmailFunction({uid: selectedUser.uid, newEmail});
            alert(result.data.message);
            fetchUsers();
            setOpenEmailDialog(false);
        } catch (error) {
            console.error('Error updating email:', error);
            alert(error.message);
        }
    };

    const handleUpdatePassword = async () => {
        try {
            const updateUserPasswordFunction = httpsCallable(functions, 'updateUserPassword');
            const result = await updateUserPasswordFunction({uid: selectedUser.uid, newPassword});
            alert(result.data.message);
            setOpenPasswordDialog(false);
        } catch (error) {
            console.error('Error updating password:', error);
            alert(error.message);
        }
    };

    const handleDeleteUser = async (uid) => {
        if (!isAdmin) {
            alert('You do not have permission to delete users.');
            return;
        }
        try {
            const deleteUserFunction = httpsCallable(functions, 'deleteUser');
            const result = await deleteUserFunction({uid});
            alert(result.data.message);
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
            alert(error.message);
        }
    };

    return (
        <Box sx={{padding: 3}}>
            <Paper sx={{padding: 3, boxShadow: 3}}>
                <Typography variant="h4" sx={{marginBottom: 2, textAlign: 'center', color: '#333'}}>
                    {isAdmin ? 'Firebase Admin Panel' : 'My Account'}
                </Typography>

                {/* Admin-only section for creating a new user */}
                {isAdmin && (
                    <Box sx={{marginBottom: 3}}>
                        <Typography variant="h6" sx={{marginBottom: 1}}>Create New User</Typography>
                        <Box component="form" onSubmit={handleCreateUser} sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                            <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" size="small" required />
                            <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} variant="outlined" size="small" required />
                            <FormControl>
                                <InputLabel>Role</InputLabel>
                                <Select value={role} onChange={(e) => setRole(e.target.value)} label="Role">
                                    <MenuItem value="viewer">Viewer</MenuItem>
                                    <MenuItem value="editor">Editor</MenuItem>
                                    <MenuItem value="admin">Admin</MenuItem>
                                </Select>
                            </FormControl>
                            <Button type="submit" variant="contained" color="primary" startIcon={<PersonAddAltIcon />}>
                                Create User
                            </Button>
                        </Box>
                    </Box>
                )}

                <Divider sx={{marginY: 2}} />

                {/* Fetch Users Button (Admin only) */}
                {isAdmin && (
                    <Box sx={{marginBottom: 3}}>
                        <Button variant="contained" color="secondary" onClick={fetchUsers}>Fetch Users</Button>
                    </Box>
                )}

                {/* Display User List */}
                <Paper variant="outlined" sx={{maxHeight: '400px', overflowY: 'auto'}}>
                    <List>
                        {users.length > 0 ? (
                            users.map((user) => (
                                <ListItem key={user.uid} divider>
                                    <ListItemText
                                        primary={user.email}
                                        secondary={isAdmin ? `Role: ${user.role || 'viewer'} | ${user.displayName || 'No display name'}` : `${user.displayName || 'No display name'}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Edit Email">
                                            <IconButton edge="end" color="primary" onClick={() => { setSelectedUser(user); setOpenEmailDialog(true); }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit Password">
                                            <IconButton edge="end" color="primary" onClick={() => { setSelectedUser(user); setOpenPasswordDialog(true); }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {isAdmin && (
                                            <Tooltip title="Delete User">
                                                <IconButton edge="end" color="error" onClick={() => handleDeleteUser(user.uid)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        ) : (
                            <Typography sx={{padding: 2}}>No users available. Click "Fetch Users" to load the list.</Typography>
                        )}
                    </List>
                </Paper>
            </Paper>
            <div style={{position: 'fixed', bottom: '10px', left: '10px', display: 'flex', gap: '10px'}}>
                        {/* Retrun to Admin Home */}
                        <button
                            onClick={() => navigate('/admin')}
                            style={{
                                padding: '10px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                border: '1px solid #000000',
                                backgroundColor: 'white',
                                zIndex: 1000,
                            }}
                        >
                            Back
                        </button>
                    </div>

            {/* Dialog for Updating Email */}
            <Dialog open={openEmailDialog} onClose={() => setOpenEmailDialog(false)}>
                <DialogTitle>Update Email</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter the new email for {selectedUser?.email}:</DialogContentText>
                    <TextField autoFocus margin="dense" label="New Email" type="email" fullWidth value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEmailDialog(false)}>Cancel</Button>
                    <Button onClick={handleUpdateEmail} color="primary">Update</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Updating Password */}
            <Dialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
                <DialogTitle>Update Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter the new password for {selectedUser?.email}:</DialogContentText>
                    <TextField autoFocus margin="dense" label="New Password" type="password" fullWidth value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPasswordDialog(false)}>Cancel</Button>
                    <Button onClick={handleUpdatePassword} color="primary">Update</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FirebaseAdmin;