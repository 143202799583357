import React, { useState, useEffect } from 'react';
import { Button, Table, Drawer, Form, Input, Select, message, Menu, Dropdown, Pagination, Spin, Alert } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const ResourceManager = () => {
  const [resources, setResources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerMode, setDrawerMode] = useState('');
  const [newItem, setNewItem] = useState({});
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResources, setTotalResources] = useState(0);
  const [error, setError] = useState(null); // State to manage errors

  useEffect(() => {
    fetchResources(limit, currentPage);
  }, [limit, currentPage]);

  const handleLimitChange = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const ActionMenu = ({ record }) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleDrawerOpen('editResource', record)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleRemoveItem(record.id)} danger>
        Delete
      </Menu.Item>
    </Menu>
  );

  const fetchWithRetry = async (fetchFunction, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fetchFunction();
      } catch (error) {
        if (i === retries - 1) {
          throw error;
        }
        console.warn('Retrying fetch...', i + 1);
      }
    }
  };

  const fetchResources = async (limit = 10, page = 1) => {
    setLoading(true);
    setError(null);
    try {
      await fetchWithRetry(async () => {
        const response = await axios.get(`/api/resources?limit=${limit}&page=${page}`);
        setResources(response.data.data);
        setTotalResources(response.data.total);
      });
    } catch (error) {
      console.error('Error fetching resources:', error);
      setError('Error fetching resources. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategoriesAndGroups = async () => {
    setLoading(true);
    setError(null);
    try {
      await fetchWithRetry(async () => {
        const categoriesResponse = await axios.get('/api/categories');
        const groupsResponse = await axios.get('/api/groups');
        setCategories(categoriesResponse.data);
        setGroups(groupsResponse.data);
      });
    } catch (error) {
      console.error('Error fetching categories or groups:', error);
      setError('Error fetching categories or groups');
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerOpen = async (mode, item = {}) => {
    setLoading(true);
    try {
      await fetchCategoriesAndGroups(); // Ensure categories and groups are fetched before proceeding
      setDrawerMode(mode);

      if (mode === 'editResource' && item) {
        setNewItem({
          ...item,
          category_ids: item.categories?.map((cat) => cat.id) || [],
          group_ids: item.groups?.map((group) => group.id) || [],
        });
      } else {
        setNewItem(item);
      }

      setDrawerVisible(true);
    } catch (error) {
      console.error('Error fetching categories and groups:', error);
      message.error('Failed to fetch categories or groups, please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
    setNewItem({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name, value) => {
    setNewItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (drawerMode === 'addResource') {
        if (!newItem.name || !newItem.description || !newItem.url) {
          message.error('Please fill in all required fields for the resource');
          return;
        }
        await axios.post('/api/resources', newItem);
        message.success('Resource added successfully');
      } else if (drawerMode === 'editResource') {
        await axios.put(`/api/resources/${newItem.id}`, newItem);
        message.success('Resource updated successfully');
      }

      fetchResources(limit, currentPage);
      handleDrawerClose();
    } catch (error) {
      console.error(`Error saving ${drawerMode}:`, error);
      message.error(`Error saving ${drawerMode}: ${error.response?.data || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveItem = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/api/resources/${id}`);
      message.success('Resource deleted successfully');
      fetchResources(limit, currentPage);
    } catch (error) {
      console.error('Error deleting resource:', error);
      message.error('Error deleting resource');
    } finally {
      setLoading(false);
    }
  };

  const resourceColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'URL', dataIndex: 'url', key: 'url' },
    { title: 'Weight', dataIndex: 'weight', key: 'weight' },
    {
      title: 'Categories',
      key: 'categories',
      render: (_, record) => record.categories?.map((cat) => cat.name).join(', ') || '',
    },
    {
      title: 'Groups',
      key: 'groups',
      render: (_, record) => record.groups?.map((group) => group.name).join(', ') || '',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={<ActionMenu record={record} />} trigger={['click']}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Select defaultValue={10} onChange={handleLimitChange} style={{ width: 120, marginRight: 16 }}>
          <Option value={10}>10</Option>
          <Option value={25}>25</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
        </Select>
        <Button type="primary" onClick={() => handleDrawerOpen('addResource')}>
          Add Resource
        </Button>
      </div>

      {loading ? (
        <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
      ) : error ? (
        <Alert message="Error" description={error} type="error" showIcon style={{ marginBottom: 16 }} />
      ) : resources.length === 0 ? (
        <Alert message="No resources available" type="info" showIcon style={{ marginBottom: 16 }} />
      ) : (
        <Table
          dataSource={resources}
          columns={resourceColumns}
          rowKey="id"
          pagination={false} // Custom pagination, so disable default pagination
        />
      )}

      <Pagination
        current={currentPage}
        pageSize={limit}
        total={totalResources}
        onChange={handlePageChange}
        style={{ marginTop: 16, textAlign: 'center' }}
      />

      <Drawer
        title={drawerMode === 'addResource' ? 'Add Resource' : 'Edit Resource'}
        width={480}
        visible={drawerVisible}
        onClose={handleDrawerClose}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} type="primary">
              Save
            </Button>
          </div>
        }
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input name="name" value={newItem.name || ''} onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Description">
            <Input name="description" value={newItem.description || ''} onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="URL">
            <Input name="url" value={newItem.url || ''} onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Weight">
            <Input name="weight" value={newItem.weight || ''} onChange={handleInputChange} type="number" />
          </Form.Item>
          <Form.Item label="Categories">
            <Select
              mode="multiple"
              value={newItem.category_ids || []}
              onChange={(value) => handleSelectChange('category_ids', value)}
              placeholder="Select categories"
            >
              {categories.map((category) => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Groups">
            <Select
              mode="multiple"
              value={newItem.group_ids || []}
              onChange={(value) => handleSelectChange('group_ids', value)}
              placeholder="Select groups"
            >
              {groups.map((group) => (
                <Option key={group.id} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default ResourceManager;